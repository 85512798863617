import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width||34}
    height={props.width||34}
    viewBox="0 0 34 34"
    fill="none"
    {...props}
  >
    <path
      fill={props.color||"#fff"}
      d="M32.086 25.239h-3.742a.621.621 0 0 1-.624-.624c0-5.835-4.754-10.59-10.59-10.59-5.835 0-10.59 4.755-10.59 10.59a.621.621 0 0 1-.623.624H2.175a.621.621 0 0 1-.624-.624c0-8.593 6.986-15.58 15.58-15.58 8.593 0 15.593 6.986 15.593 15.58a.633.633 0 0 1-.638.624Zm-3.119-1.262h2.495c-.332-7.609-6.625-13.694-14.318-13.694S3.16 16.368 2.826 23.977h2.495c.333-6.237 5.503-11.213 11.823-11.213s11.49 4.976 11.823 11.213Z"
    />
    <path
      fill={props.color||"#fff"}
      d="M17.143 14.025a.621.621 0 0 1-.623-.623V9.659c0-.346.277-.623.623-.623.347 0 .624.277.624.623v3.743a.621.621 0 0 1-.624.623ZM9.216 17.31a.607.607 0 0 1-.444-.18l-2.647-2.647a.62.62 0 0 1 0-.887.62.62 0 0 1 .887 0l2.647 2.647a.62.62 0 0 1 0 .887.696.696 0 0 1-.443.18ZM25.072 17.31a.607.607 0 0 1-.444-.18.62.62 0 0 1 0-.887l2.648-2.647a.62.62 0 0 1 .887 0 .62.62 0 0 1 0 .887l-2.647 2.633a.645.645 0 0 1-.444.195ZM17.143 25.239a2.502 2.502 0 0 1-2.495-2.495 2.502 2.502 0 0 1 2.495-2.495 2.502 2.502 0 0 1 2.495 2.495 2.502 2.502 0 0 1-2.495 2.495Zm0-3.742c-.679 0-1.247.554-1.247 1.247 0 .68.554 1.247 1.247 1.247.693 0 1.248-.554 1.248-1.247a1.26 1.26 0 0 0-1.248-1.247Z"
    />
    <path
      fill={props.color||"#fff"}
      d="M18.694 22.342a.624.624 0 0 1-.61-.471l-.956-3.188-.957 3.201a.62.62 0 0 1-.776.416.62.62 0 0 1-.416-.776l1.567-5.198a.624.624 0 0 1 1.192 0l1.566 5.198a.816.816 0 0 1 .028.18.645.645 0 0 1-.638.638Z"
    />
  </svg>
)
export default SvgComponent
