
import { connect } from 'react-redux';
import { sethomepagePopup } from '../../actions/actions';
import DetailsSvg from '../../svgs/map/Details';
import ReloadSvg from '../../svgs/map/Reload';
import { DEVICE_TYPE, DEVLICE_LABEL } from '../../dto/devices';
import { DEVICE_COLOR_DICT } from '../../config/style.config';
import { isWatch20MinutesDisplayOffline, isWatchDangerZone, isWatchMinutesDisplayOfflineThreshold } from '../../config';

function Screen(props){
    console.log(props.control?.homepagePopup)
    return(
        <div className='h-full absolute top-0 left-0 p-[24px] d-flex-m-hidden flex-col text-white w-[550px]' 
            style={{borderRight:'2px solid white',maxWidth:550,overflowY:'auto',
                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',
            }}
        >
            <div className="text-base flex flex-row w-full justify-between items-center">
                <div className="font-bold">Device Status </div>
                <div onClick={()=>{props.dispatch(sethomepagePopup(null))}} className="text-sm ml-[50px] cursor-pointer flex flex-row items-center">
                    back
                    <DetailsSvg className='ml-2'/>
                </div>
            </div>
            <div className='h-[.5px] w-full bg-white mt-[10px]'></div>

            <div className='w-full mt-[10px] mb-[10px] flex-1 pr-[4px]' style={{overflowY:'auto'}}>
                {props.control?.homepagePopup?.data?.map((category,ci)=>
                    <div className='text-sm text-white flex flex-col w-full mt-5' key={ci}>
                        <div className='py-[4px] flex flex-row items-center'>
                            <div className="w-[7px] h-[7px] rounded-[4px] mr-[8px]" style={{backgroundColor:DEVICE_COLOR_DICT[category?.type]}}/>
                            {DEVLICE_LABEL[category?.type]}
                        </div>
                        <div className='h-[.5px] w-full bg-white mt-[10px] mb-[10px]'></div>
                        {category?.type===DEVICE_TYPE.LOCK
                            ?category?.data?.map((item,index)=>
                                <div key={index} className='flex flex-row w-full justify-between text-[11px] font-[300]'>
                                    <div>{item?.properties?.data?.data?.name||item?.properties?.id}</div>
                                    <div className='flex flex-row gap-[8px]'>
                                        {item?.properties?.data?.data?.isConnected?
                                            <div className='text-[#29FFD9]'>Connected</div>
                                            :<div className='text-[#ccc]'>Sleep</div>
                                        }
                                        <div className='w-[80px]'>Battery {item?.properties?.data?.data?.battery||'-'}%</div>
                                        <div className='w-[210px]'>Last update: {new Date(item?.properties?.data?.modifyDate).toLocaleString()}</div>
                                    </div>
                                </div>
                            )
                            :category?.type===DEVICE_TYPE.WATCH
                            ?category?.data?.map((item,index)=>
                                <div key={index} className='flex flex-row w-full justify-between text-[11px] font-[300]'>
                                    <div>{item?.properties?.data?.data?.name||item?.properties?.id}</div>
                                    <div className='flex flex-row gap-[8px]'>
                                        {isWatch20MinutesDisplayOffline?
                                            ((item?.properties?.data?.data?.isConnected&&(Date.now()-item?.properties?.data?.data?.statusLastUpdate) <= 1000*60*isWatchMinutesDisplayOfflineThreshold )?
                                                <div className='text-[#29FFD9]'>Active</div>
                                                :<div className='text-[#ccc]'>Sleep</div>)
                                            :(item?.properties?.data?.data?.isConnected?
                                                <div className='text-[#29FFD9]'>Active</div>
                                                :<div className='text-[#ccc]'>Sleep</div>)
                                        }
                                        <div className='w-[80px]'>Battery {item?.properties?.data?.data?.battery||'-'}%</div>
                                        <div className='w-[210px]'>Last update: {new Date(item?.properties?.data?.modifyDate).toLocaleString()}</div>
                                    </div>
                                </div>
                            )
                            :category?.type===DEVICE_TYPE.CAMERA
                            ?category?.data?.map((item,index)=>
                                <div key={index} className='flex flex-row w-full justify-between text-[11px] font-[300]'>
                                    <div>{item?.properties?.id}</div>
                                    <div className='flex flex-row gap-[8px]'>
                                        {item?.properties?.data?.webrtcValidTil>Date.now()?
                                            <div className='text-[#63BDFF]'>Activated</div>
                                            :<div className='text-[#ccc]'>Sleep</div>
                                        }
                                        <div className=''>Last update: {new Date(item?.properties?.data?.modifyDate).toLocaleString()}</div>
                                    </div>
                                </div>
                            )
                            :category?.type===DEVICE_TYPE.DANGER_ZONE
                            ?category?.data?.map((item,index)=>
                                <div key={index} className='flex flex-row w-full justify-between text-[11px] font-[300]'>
                                    <div>{item?.properties?.id}</div>
                                    <div className='flex flex-row gap-[8px]'>
                                        <div className='text-[#63BDFF]'>Activated</div>
                                        <div className=''>Last update: {new Date().toLocaleString()}</div>
                                    </div>
                                </div>
                            )
                            :category?.type===DEVICE_TYPE.CONFINED_SPACE
                            ?category?.data?.map((item,index)=>
                                <div key={index} className='flex flex-row w-full justify-between text-[11px] font-[300]'>
                                    <div>{item?.properties?.id}</div>
                                    <div className='flex flex-row gap-[8px]'>
                                        <div className='text-[#63BDFF]'>Activated</div>
                                        <div className=''>Last update: {new Date().toLocaleString()}</div>
                                    </div>
                                </div>
                            )
                            :<div/>
                        }
                        <div className='h-[.5px] w-full bg-white mt-[10px]'></div>
                    </div>
                )}
            </div>
           

        </div>
    )
}


export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
  })(Screen)