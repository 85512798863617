import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width||17}
    height={props.width?props.width/17*31:31}
    fill="none"
    viewBox="0 0 17 31"
    {...props}
  >
    <path stroke={props.color||"#fff"} strokeWidth={0.5} d="m16.221.118-16 30" />
    <path stroke={props.color||"#fff"} strokeWidth={2} d="m8.882.471-8 15" />
  </svg>
)
export default SvgComponent
