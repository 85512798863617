import { useLayoutEffect, useState } from "react"
import { connect } from "react-redux"
import { fetchSnapshotByTime } from "../../services/cameras.service"
import { THEMES } from "../../dto/theme"

function Component(props){
    const [data,setData]=useState(null)
    const fetchPhoto=async()=>{
        let result = await fetchSnapshotByTime(props.token?.token,props.control?.projectId,props.deviceId,props.time,true)
       if(result?.result?.[0]?.time===props.time){
            setData(result?.result?.[0])
       }else{
            let result2 = await fetchSnapshotByTime(props.token?.token,props.control?.projectId,props.deviceId,props.time,false)
            if(result2?.result?.[0]?.data){
                let Diff1 = (result?.result?.[0]?.time - props.time) || 9999999999999
                let Diff2 = (props.time - result2?.result?.[0]?.time) || 9999999999999
                if(Diff1<Diff2 && result?.result?.[0]){
                    setData(result?.result?.[0])
                }else if(setData(result2?.result?.[0])){
                    setData(result2?.result?.[0])
                }
            }
        }
    }
    useLayoutEffect(()=>{
        if(props.deviceId&&props.time)
            fetchPhoto()
    },[props.deviceId,props.time,props.control?.projectId])

    const closePopup=()=>{if(props.close)props.close()}

    return(
        <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)',zIndex:10}}>
            <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative w-[95%] h-[95%] flex items-center justify-center' style={{border:'.5px solid #FFFFFF80'}}>
                
                {data?
                    <div className="w-full h-full cursor-pointer" style={{filter: (props.control.theme===THEMES.DARK)?'none':'invert(1) hue-rotate(180deg)'}}>
                        <img src={data?.data?.s3url||`data:image/jpg;base64,${data?.data?.base64_data}`} style={{objectFit:'contain',height:'100%'}} className="w-full"/>
                    </div>
                    :<div className="text-white text-[20px]">No Snapshot</div>
                }
                <div className='absolute top-[24px] right-[24px] text-[36px] font-bold ml-20 cursor-pointer select-none' onClick={closePopup}>X</div>  


            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)