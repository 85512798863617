import React, { useEffect, useState } from "react";
import ChartChainLink from '../svgs/ChartChainLink'

function CopyLink(props){
    const [clicked, setClicked] = useState(false)
    function handleCopy(){
        setClicked(true)
        navigator.clipboard.writeText(window.location.href)
        setTimeout(() => setClicked(false), 600)
    }
    return <div onClick={handleCopy} className="cursor-pointer relative">
        {clicked && <div className="text-[12px] absolute w-[200px] ml-2 text-white left-5">copied to clipboard</div>}
        <ChartChainLink color={props.color||null}/>
    </div>
}

export default CopyLink