import { useEffect, useLayoutEffect, useState } from "react"
import { connect } from "react-redux"
import { fetchSnapshotByTime } from "../../services/cameras.service"
import { isHideReportSnapShot, server_ip } from "../../config"
import { THEMES } from "../../dto/theme"
import AlertPhotoSvg from '../../svgs/camera/AlertPhotoSvg'
import { updateEventById } from "../../services/watches.service"

function Component(props){
    const [data,setData]=useState(null)

    const fetchPhoto=async()=>{
        if(props?.item?.s3__){
            console.log('pre-fetched')
            return}
        let s3url=null
        let result = await fetchSnapshotByTime(props.token?.token,props.control?.projectId,props.deviceId,props.time,true)
        if(result?.result?.[0]?.time===props.time){
                setData(result?.result?.[0])
                s3url=result?.result?.[0].data.s3url
        }else{
            let result2 = await fetchSnapshotByTime(props.token?.token,props.control?.projectId,props.deviceId,props.time,false)
            if(result2?.result?.[0]?.data){
                let Diff1 = (result?.result?.[0]?.time - props.time) || 9999999999999
                let Diff2 = (props.time - result2?.result?.[0]?.time) || 9999999999999
                if(Diff1<Diff2 && result?.result?.[0]){
                    setData(result?.result?.[0])
                    s3url=result?.result?.[0].data.s3url
                }else if(setData(result2?.result?.[0])){
                    setData(result2?.result?.[0])
                    s3url=result2?.result?.[0].data.s3url
                }
            }
        }
        if(s3url&&props?.item?._id){
            let updateResult = await updateEventById(props.token?.token,props.item?._id,{"s3__":s3url})
            // console.log(updateResult)
        }
    }

    // function toDataUrl(url, callback) {
    //     var xhr = new XMLHttpRequest();
    //     xhr.onload = function() {
    //         var reader = new FileReader();
    //         reader.onloadend = function() {
    //             callback(reader.result);
    //         }
    //         reader.readAsDataURL(xhr.response);
    //     };
    //     xhr.open('GET', url);
    //     xhr.responseType = 'blob';
    //     xhr.send();
    // }

    // function toDataUrl(url, callback, compressionQuality=0.1) {
    //     var xhr = new XMLHttpRequest();
    //     xhr.onload = function() {
    //         var reader = new FileReader();
    //         reader.onloadend = function() {
    //             var img = new Image();
    //             img.src = reader.result;
    
    //             img.onload = function() {
    //                 var canvas = document.createElement('canvas');
    //                 var ctx = canvas.getContext('2d');
    //                 canvas.width = img.width;
    //                 canvas.height = img.height;
    //                 ctx.drawImage(img, 0, 0);
    
    //                 // Get the base64 string with compression
    //                 var base64String = canvas.toDataURL('image/jpeg', compressionQuality); // Change the format and quality as needed
    
    //                 callback(base64String);
    //             };
    //         };
    //         reader.readAsDataURL(xhr.response);
    //     };
    //     xhr.open('GET', url);
    //     xhr.responseType = 'blob';
    //     xhr.send();
    // }

    const [base64,setBase64]=useState(null)
    useEffect(()=>{
        // if(data?.data?.s3url&&props.isReport){
        //     toDataUrl(data?.data?.s3url.replace('https://novalinx-constructions.s3.ap-southeast-1.amazonaws.com',server_ip+'/s3'), function(myBase64) {
        //         setBase64(myBase64); // myBase64 is the base64 string
        //     });
        // }
        if(data?.data?.s3url&&props.setData)
            props.setData(d=>{
                let dd=[...d]
                dd[props.index].s3__ = data?.data?.s3url
                //dd[props.index].base64 = myBase64
                return dd
            })
    },[data,props.isReport])

    useLayoutEffect(()=>{
        let timeOut=null
        if(props.deviceId&&props.time){
            if(props.isReport){
                timeOut= setTimeout(fetchPhoto , props.index*500 )
            }else {
                fetchPhoto()
            }
        }
        return ()=>{
            if(timeOut){
                clearTimeout(timeOut)
            }
        }
    },[props.deviceId,props.time,props.control?.projectId,props.isReport,props.index])

    const [showImagePopup,setshowImagePopup]=useState(false)
    const showPopup=()=>{if(props.isReport)return;setshowImagePopup(true)}
    const closePopup=()=>{setshowImagePopup(false)}

    if(isHideReportSnapShot&&props.isReport)return(
        <div className="flex-[2] flex flex-row items-center gap-[4px] cursor-pointer">
            Snapshot <AlertPhotoSvg color={((props.isReport))?'black':'white'}/>
        </div>
        )

    return(
        <div className='w-full flex flex-col justify-center items-center' style={{aspectRatio:1920/1080,height:undefined}}>
            {data?
                <div className="w-full h-full cursor-pointer" style={{filter: ((props.isReport)||props.control.theme===THEMES.DARK)?'none':'invert(1) hue-rotate(180deg)'}}>
                    <img onClick={showPopup} src={base64|| data?.data?.s3url||`data:image/jpg;base64,${data?.data?.base64_data}`} style={{objectFit:'contain',height:'100%'}} className="w-full"/>
                </div>
                :<div className="text-white text-[10px]">No Snapshot</div>
            }

            {showImagePopup&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)',zIndex:10,filter: (props.control.theme===THEMES.DARK)?'none':'invert(1) hue-rotate(180deg)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative w-[95%] h-[95%]' style={{border:'.5px solid #FFFFFF80'}}>       
                    <img src={props?.item?.s3__||data?.data?.s3url||`data:image/jpg;base64,${data?.data?.base64_data}`} style={{objectFit:'contain',height:'100%'}} className="w-full"/>
                    <div className='absolute top-[24px] right-[24px] text-[36px] font-bold ml-20 cursor-pointer select-none' onClick={closePopup}>X</div>  
                </div>
            </div>}

        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)