import { useLayoutEffect, useState } from "react"
import { connect } from "react-redux"
import { server_ip } from "../../config"
import { THEMES } from "../../dto/theme"

function Component(props){

    const closePopup=()=>{if(props.close)props.close()}

    return(
        <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)',zIndex:10}}>
            <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative w-[95%] h-[95%] flex items-center justify-center' style={{border:'.5px solid #FFFFFF80'}}>
                
                <div className='absolute top-[24px] right-[24px] text-[36px] font-bold ml-20 cursor-pointer select-none ' style={{zIndex:10}} onClick={closePopup}>X</div>  

                 {(props.data?.video||props.data?.s3video)?
                    <div className="w-full h-full cursor-pointer" style={{filter: (props.control.theme===THEMES.DARK)?'none':'invert(1) hue-rotate(180deg)'}}>
                        <video src={props.data?.s3video||`${server_ip}/api/documents/${props.data?.video}`} style={{objectFit:'contain',height:'100%'}} className="w-full" controls={true}/>
                    </div>
                    :<div className="text-white text-[20px]">No Video</div>
                }  


            </div>
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)