import './App.css';
import { Routes, Route } from 'react-router-dom'
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import {COOKIESPREFIX, is3WSD18, isFLN, isOnlyTracking, isWatchDangerZone} from './config';
import { Login } from './actions/actions'
import NavBar from './components/NavBar';
import React, {useRef} from 'react'
//pages
import LoginScreen from './screens/Login'
import PageNotFound from './screens/PageNotFound'
import Home from './screens/Home';
import Tracking from './screens/Tracking'
import TrackingDetail from './screens/TrackingDetail';
import LiveView from './screens/LiveView';
import LiveViewP2 from './screens/LiveViewP2';
import SmartWatch from './screens/SmartWatch';
import SmartWatchP2 from './screens/SmartWatchP2';
import SmartWatchP3 from './screens/SmartWatchP3';
import SmartWatchSetting from './screens/SmartWatchSetting';
import SmartLock from './screens/SmartLock';
import SmartLockP2 from './screens/SmartLockP2';
import Camera from './screens/Camera'
import Camera_FLN from './screens/Camera_FLN'
import CameraP2 from './screens/CameraP2'
import PermitSystem from './screens/PermitSystem';
import PermitTemplate from './screens/PermitTemplate';
import PermitTemplateAdd from './screens/PermitTemplateAdd'
import PertmitCreate from './screens/PermitCreate'
import PermitWorkerList from './screens/PermitWorkerList'
import ConfinedSpace from './screens/ConfinedSpace';
import ConfinedSpaceHistory from './screens/ConfinedSpaceHistory';
import ConfinedSpaceAlert from './screens/ConfinedSpaceAlert';
import Summary from './screens/Summary';
import Summary_3WSD from './screens/Summary_3WSD';
import DangerZone from './screens/DangerZone';
import DangerZone2 from './screens/DangerZone2';
import UserManagement from './screens/UserManagement';
import UserPermission from './screens/UserPermission';
import ConfinedSpaceSetting from './screens/ConfinedSpaceSetting';
import AlertSetting from './screens/AlertSetting';
import DangerZoneWatch from './screens/DangerZoneWatch';
import Setting from './screens/Setting';
import VR from './screens/VR';
import { THEMES } from './dto/theme';
//
import {postValidTokenCheck} from './services/users.service'

function App(props) {
  const [cookies, setCookie, removeCookie] = useCookies([`@${COOKIESPREFIX}_cred`, `@${COOKIESPREFIX}_token`]);
  const portalRef = useRef(null)
  useEffect(() => {
    // console.log(props)
    async function check_token() {
      let login_cred = cookies[`@${COOKIESPREFIX}_cred`];
      let token = cookies[`@${COOKIESPREFIX}_token`];
      if (token && login_cred) {
        let res = await postValidTokenCheck(token)
        if (res.errno === 0 && login_cred.username && login_cred.usertype) {
          props.dispatch(Login(true, token, login_cred.usertype, login_cred.username))
        }
      } else {
        removeCookie(`@${COOKIESPREFIX}_token`, '/')
      }
    }
    check_token()
  }, [])

  if (!props.token.isLogin)
    return (
      <div className="flex h-[100vh] flex flex-col bg-black" style={{fontFamily: 'Oxygen',letterSpacing:1.05}}>
        <Routes >
          <Route path="/tracking/detail/:_id" element={<TrackingDetail />} />
          <Route path="*" element={<LoginScreen />} />
        </Routes>
      </div>
    );
  return (
    <div className="flex h-[100vh] flex flex-col bg-black min-h-[100vh]" style={{fontFamily: 'Oxygen',letterSpacing:1.05,filter: (props.control.theme===THEMES.DARK)?'none':'invert(1) hue-rotate(180deg)'}}>
      <div ref={portalRef} style={{zIndex: 999999}} className=''></div>
      <NavBar />
      <Routes >
        <Route path="/" element={isOnlyTracking?<Tracking portalRef={portalRef}/>:<Home />} />
        <Route path="/live" element={<LiveView />} />
        <Route path="/live/alert" element={<LiveViewP2 />} />
        <Route path="/tracking" element={<Tracking portalRef={portalRef}/>} />
        <Route path="/tracking/detail" element={<TrackingDetail portalRef={portalRef}/>} />
        <Route path="/tracking/detail/:_id" element={<TrackingDetail portalRef={portalRef}/>} />
        <Route path="/smartwatch" element={<SmartWatch />} />
        <Route path="/smartwatch/device-management" element={<SmartWatchP2/>} />
        <Route path="/smartwatch/send-message" element={<SmartWatchP3/>} />
        <Route path="/smartwatch/setting" element={<SmartWatchSetting/>} />
        <Route path="/smartlock" element={<SmartLock />} />
        <Route path="/smartlock/device-management" element={<SmartLockP2 />} />
        <Route path="/camera" element={isFLN?<Camera_FLN/>:<Camera />} />
        <Route path="/camera/alert" element={<CameraP2 />} />
        <Route path="/permit" element={<PermitSystem />} />
        <Route path="/permit/create" element={<PertmitCreate portalRef={portalRef}/>} />
        <Route path="/permit/create/:_id" element={<PertmitCreate portalRef={portalRef}/>} />
        <Route path="/permit/template" element={<PermitTemplate portalRef={portalRef}/>} />
        <Route path="/permit/template/add" element={<PermitTemplateAdd portalRef={portalRef}/>} />
        <Route path="/permit/worker-list" element={<PermitWorkerList/>} />
        <Route path="/confined-space" element={<ConfinedSpace/>} />
        <Route path="/confined-space/history" element={<ConfinedSpaceHistory/>} />
        <Route path="/confined-space/alert" element={<ConfinedSpaceAlert/>} />
        <Route path='/confined-space/setting' element={<ConfinedSpaceSetting/>}/>
        <Route path="/vr" element={<VR/>} />
        <Route path="/summary" element={is3WSD18?<Summary_3WSD/>:<Summary/>} />
        <Route path="/danger-zone" element={isWatchDangerZone?<DangerZoneWatch/>:<DangerZone/>} />
        <Route path="/danger-zone/alert" element={isWatchDangerZone?<DangerZoneWatch/>:<DangerZone2/>} />
        <Route path="/user-management" element={<UserManagement/>} />
        <Route path="/user-management/permission" element={<UserPermission/>} />
        <Route path="/setting/alert" element={<AlertSetting/>} />
        <Route path="/setting" element={<Setting/>} />
        
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default connect((store) => {
  return {
    token: store.tokenReducer,
    control: store.controlReducer,
  }
})(App)

