import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import CrossIcon from '../../svgs/map/CloseIcon';
import WarningIcon from '../../svgs/map/Warning';
import CloseIcon from '../../svgs/map/CloseIcon';
import ExpandPopup from '../../svgs/map/ExpandPopup';
import { fetchLatestAlerts, putUpdateAlerts, putUpdateAllAlerts } from "../../services/request.service";
function Component(props){
    const navigate = useNavigate()
    const [isExpanded,setisExpanded]=useState(true)
    useEffect(()=>{
        if(window.innerWidth<1024)
            setisExpanded(false)
    },[window.innerWidth])
    const [alertData, setAlertData] = useState([])
    const [position,setPosition]=useState({x:window.innerWidth-320,y:window.innerHeight-320})
    const [delta,setDelta]=useState({x:0,y:0})
    const handleDrag=(e)=>{
        let newX=e.clientX - delta.x;
        let newY=e.clientY - delta.y;
        setPosition({
            x:newX<0?0:newX>window.innerWidth-50?window.innerWidth-50:newX ,
            y:newY<0?0:newY>window.innerHeight-50?window.innerHeight-50:newY ,
        })
    }
    const handleDragStart=(e)=>{
        setDelta({x:e.clientX-position.x,y:e.clientY-position.y})
    }
    useEffect(()=>{
        fetchData()
        const timer = setInterval(() => fetchData(), 30000) 
        function handleWindowResize() {setPosition({x:window.innerWidth-320,y:window.innerHeight-320})}
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
            clearInterval(timer)
        };
    },[])
    function formatType(str){
        const obj = {
            "LOCK_UNLOCK":'Unlock',
            "LOCK_LOCK": "Lock",
            "LOCK_INAPR_UNLOCK": "Inappropriate unlock",
            "LOCK_VIBRATION": "Vibration",
            "WATCH_SOS":'SOS Alert',
            "WATCH_DNG_ZONE": "Danger Zone",
            "WATCH_STANDSTILL": "Stand Still",
            "WATCH_HEARTRATE": "Heart Rate",
            "WATCH_BLD_OXYGEN": "Blood Oxygen",
            "WATCH_FALLDOWN": "Fall Alert",
            'CAMERA_DNG_ZONE':'Danger Zone',
            'CAMERA_NO_JACKET':'No Jactket',
            'CAMERA_NO_HELMET':'No Helmet',
            'CAMERA_FALLDOWN':'Falldown',
        }
        return obj[str]
    }
    async function fetchData(){
        const res = await fetchLatestAlerts(props.token.token,{'type':{"$nin": ["LOCK_UNLOCK", "LOCK_LOCK"]},'projectId': props.control.projectId, isAcknowledged: {$ne:true}}, {limit:10, sort: {time: -1}})
        if(res.errno)return
        setAlertData(res.result)
    }
    async function updateAlert(index, _id){
        const temp = [...alertData]
        temp.splice(index, 1)
        setAlertData(temp)
        const res = await putUpdateAlerts(props.token.token, _id, {data:{isAcknowledged:true}})
        console.log(res)
    }
    async function updateAllAlert(){
        const res = await putUpdateAllAlerts(props.token.token, props.control.projectId)
        if(res.errno)return
        setAlertData([])
    }

     //mobile version
    if(window.innerWidth<1024){
        if(isExpanded)return(
            <div className="w-full h-full absolute flex items-center justify-center bg-[#00000090] left-[0px] bottom-[0px] z-[99]">
            <div className="absolute w-[95%] left-[20px] bg-black flex-col items-center text-white" 
                style={{
                    border:'1px solid #ffffff60',borderRadius:8,overflowY:'auto',maxHeight:'90%',
                    left:'2.5%',
                    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',
                }}
            >
                <div className="w-full flex flex-row items-center px-[16px] py-[8px] cursor-grab">
                    <svg width="1" height="20" viewBox="0 0 1 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.5" y1="2.39372e-08" x2="0.499999" y2="20" stroke="white"/>
                    </svg>
                    <svg className='ml-[4px]' width="3" height="12" viewBox="0 0 3 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="1.5" y1="-7.8056e-08" x2="1.5" y2="12" stroke="white" strokeWidth="3"/>
                    </svg>

                    <div className="flex-1 font-bold ml-[16px] text-xs" style={{}}>Alert</div>
                    <div onClick={()=>setisExpanded(!isExpanded)} className="cursor-pointer">
                        <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.5919 1.39933L9.89954 10.0917L1.20715 1.39933L18.5919 1.39933Z" stroke="white"/>
                        </svg>
                    </div>
                </div>

                <div className="flex flex-col w-full items-center rounded-[8px] px-[16px] py-[8px] cursor-default" style={{}}>
                    <div className="flex flex-row justify-between w-full">
                        <div className="text-[10px] underline text-[#29FFD9] cursor-pointer" onClick={() => navigate('/summary')}>View All</div>
                        <div className="flex flex-row items-center text-[10px] text-white cursor-pointer" onClick={() => {updateAllAlert()}}><CrossIcon/> Close All Alert</div>
                    </div>
                    <div className="flex-1 w-full flex flex-col gap-[6px] mt-[4px] py-[6px] px-[6px]" style={{overflowX:'hidden',overflowY:'auto'}}>
                        {alertData.map((item,index)=>
                            <div key={index} className="w-full bg-[#FFFFFF1F] ronuded-[3px] p-[6px] flex flex-row items-center gap-[4px]" style={new Date().getTime() - new Date(item.createDate).getTime() < 60000 ? {boxShadow: '0 0 5px #29FFD9'} : {}}>
                                <WarningIcon/>
                                <div className="flex-1">
                                    <div className="text-[#ffE454] text-[10px] flex-1">{`Name: ${item.name}`}</div>
                                    <div className="flex flex-row">
                                        <div className="text-[#ffE454] text-[10px] flex-1">{`Device: ${item.device}`}</div>
                                    </div>
                                    <div className="text-[#ffE454] text-[10px] flex-1">{`Type: ${formatType(item.type)}`}</div>
                                    <div className="text-[#ffE454] text-[10px] flex-1">{`Time: ${new Date().getTime() - new Date(item.createDate).getTime() < 60000 ? 'Now' :new Date(item.createDate).toLocaleString()} `}</div>
                                </div>
                                <div className="cursor-pointer" onClick={() => {updateAlert(index, item._id)}}><CloseIcon/></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        )
        else return(
            <div className="absolute w-[90%] bg-black flex-col items-center text-white" 
            style={{
                border:'1px solid #ffffff60',borderRadius:8,
                left:8,bottom:52,
                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',
            }}
        >
            <div className="w-full flex flex-row items-center px-[16px] py-[8px] cursor-grab">
                <svg width="1" height="20" viewBox="0 0 1 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.5" y1="2.39372e-08" x2="0.499999" y2="20" stroke="white"/>
                </svg>
                <svg className='ml-[4px]' width="3" height="12" viewBox="0 0 3 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1.5" y1="-7.8056e-08" x2="1.5" y2="12" stroke="white" strokeWidth="3"/>
                </svg>
                <div className="flex-1 font-bold ml-[16px] text-xs" style={{}}>Alert</div>
                <div onClick={()=>setisExpanded(!isExpanded)} className="cursor-pointer">
                    <ExpandPopup/>
                </div>
            </div>
        </div>
        )
    }

    return(
        <div 
            onDragStart={handleDragStart}
            onDragEnd={handleDrag} draggable={true}
            className="absolute w-[280px] top-[90px] left-[20px] bg-black d-flex-m-hidden flex-col items-center text-white" 
            style={{
                border:'1px solid #ffffff60',transition:'1s all ease',borderRadius:isExpanded?8:28,overflowY:'auto',maxHeight:'90%',
                top:position.y,left:position.x,
                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',
            }}
        >
            <div className="w-full flex flex-row items-center px-[16px] py-[8px] cursor-grab">
                <svg width="1" height="20" viewBox="0 0 1 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.5" y1="2.39372e-08" x2="0.499999" y2="20" stroke="white"/>
                </svg>
                <svg className='ml-[4px]' width="3" height="12" viewBox="0 0 3 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1.5" y1="-7.8056e-08" x2="1.5" y2="12" stroke="white" strokeWidth="3"/>
                </svg>

                <div className="flex-1 font-bold ml-[16px] text-xs" style={{}}>Alert</div>
                <div onClick={()=>setisExpanded(!isExpanded)} className="cursor-pointer">
                    {isExpanded?
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.20703 9.39949L9.89941 0.707107L18.5918 9.39949H1.20703Z" stroke="white"/>
                        </svg>
                        :<svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.5919 1.39933L9.89954 10.0917L1.20715 1.39933L18.5919 1.39933Z" stroke="white"/>
                        </svg>
                    }
                </div>
            </div>

            {isExpanded&&<div className="flex flex-col w-full items-center rounded-[8px] px-[16px] py-[8px] h-[200px] cursor-default" style={{}}>
                <div className="flex flex-row justify-between w-full">
                    <div className="text-[10px] underline text-[#29FFD9] cursor-pointer" onClick={() => navigate('/summary')}>View All</div>
                    <div className="flex flex-row items-center text-[10px] text-white cursor-pointer" onClick={() => {updateAllAlert()}}><CrossIcon/> Close All Alert</div>
                </div>
                <div className="flex-1 w-full flex flex-col gap-[6px] mt-[4px] py-[6px] px-[6px]" style={{overflowX:'hidden',overflowY:'auto'}}>
                    {alertData.map((item,index)=>
                        <div key={index} className="w-full bg-[#FFFFFF1F] ronuded-[3px] p-[6px] flex flex-row items-center gap-[4px]" style={new Date().getTime() - new Date(item.createDate).getTime() < 60000 ? {boxShadow: '0 0 5px #29FFD9'} : {}}>
                            <WarningIcon/>
                            <div className="flex-1">
                                <div className="text-[#ffE454] text-[10px] flex-1">{`Name: ${item.name}`}</div>
                                <div className="flex flex-row">
                                    <div className="text-[#ffE454] text-[10px] flex-1">{`Device: ${item.device}`}</div>
                                </div>
                                <div className="text-[#ffE454] text-[10px] flex-1">{`Type: ${formatType(item.type)}`}</div>
                                <div className="text-[#ffE454] text-[10px] flex-1">{`Time: ${new Date().getTime() - new Date(item.createDate).getTime() < 60000 ? 'Now' :new Date(item.createDate).toLocaleString()} `}</div>
                            </div>
                            <div className="cursor-pointer" onClick={() => {updateAlert(index, item._id)}}><CloseIcon/></div>
                        </div>
                    )}
                </div>
            </div>}

        </div>
    )
}

export default connect((store)=>{
    return{
        token:store.tokenReducer,
        control:store.controlReducer,
    }
})(Component)