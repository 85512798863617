import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import TotalAlerts from '../components/home/TotalAlerts'
import DropdownMenu from '../components/DropdownMenu'
import TrendCharts from '../components/charts/TrendCharts';
import { handleFilterDate, validateTimeRangeSelect,yearMonthDay } from '../actions/actionsDateTime';
//
import { fetchAICamEventStats } from "../services/aiCamera.service";
import { fetchWatchEventStats,fetchEventStatsByType } from '../services/watches.service';
import { fetchLockEventStats } from '../services/locks.service';
//
import CameraAlert from '../components/summary/CameraAlert'
import SmartLockAlert from '../components/summary/SmartLockAlert'
import SmartWatchAlert from '../components/summary/SmartWatchAlert'
import DangerZoneSum from '../components/summary/DangerZoneSum'
import ConfinedSummary from '../components/summary/ConfinedSummary'
import DangerZoneWatchAlert from '../components/summary/DangerZoneWatchAlert'
import StatusPie from '../components/summary/StatusPie';
//
import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf";
import { isHideConfinedSpace, isHideDangerZone, isHideSmartLock, isHideSmartCamera, isWatchDangerZone, isPrintWhiteMode, isHideSmartWatch, watchLatOffset, watchLngOffset } from '../config';
import LoadingPage from '../components/Loading';
import {utils,writeFile} from 'xlsx';
import { THEMES } from '../dto/theme';

function Screen(props) {
    const [isLoading,setisLoading]=useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [showReportPreview,setshowReportPreview]=useState(false)
    const [alertData, setAlertData] = useState()
    const [popType, setPopType] = useState(undefined)
    const [hoverZoom, setHoverZoom] = useState(true)
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    const fromRef = useRef(null)
    const toRef = useRef(null)
    const divToPrintRef = useRef(null)
    // function downloadReport1() {
    //     const input = divToPrintRef.current
    //     // input.style.filter = 'invert(1)';
    //     html2canvas(input)
    //       .then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
            
            
    //         var imgWidth = 210; 
    //         var pageHeight = 295;  
    //         var imgHeight = canvas.height * imgWidth / canvas.width;
    //         var heightLeft = imgHeight;
    //         var backgroundColor = '#FFFFFF'
    //         var doc = new jsPDF('p', 'mm', 'a4', true);
    //         //doc.fill="#FFFFFF"
    //         //doc.rect(0, 0, 10000, 10000, "F");
    //         var position = 0;

    //         doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,backgroundColor, undefined, 'FAST');
    //         heightLeft -= pageHeight;
    //         while (heightLeft >= 0) {
    //             position = heightLeft - imgHeight;
    //             doc.addPage();
    //             //doc.fill="#FFFFFF"
    //             //doc.rect(0, 0, 10000, 10000, "F");
    //             doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,backgroundColor, undefined, 'FAST');
    //             heightLeft -= pageHeight;
    //         }
    //         doc.save( 'file.pdf');
    //     });
    // }

    const downloadExcel = async () => {
        if(isLoading)return
        setisLoading(true)

        const wb = utils.book_new();

        const array=[
            ['Date',(filterFrom && filterTo ? `${filterFrom} / ${filterTo}` :filterDate)],
            [],
            ['Device','Total Alert'],
        ]
        if(!isHideSmartCamera){
            array.push(['AI Camera',cameraPrintRef.current.obtainData()?.length||0])
        }
        if(!isHideSmartLock){
            array.push(['Smart Lock',lockPrintRef.current.obtainData()?.length||0])
        }
        if(!isHideSmartWatch){
            array.push(['Smart Watch',watchPrintRef.current.obtainData()?.length||0])
        }
        if(isWatchDangerZone){
            array.push(['Danger Zone',dangerZoneWatchPrintRef.current.obtainData()?.length||0])
        }
        if((!isWatchDangerZone)&&(!isHideDangerZone)){
            let cameraData = dangerZonePrintRef.current.obtainData();
            cameraData= cameraData?.reduce((acc,item)=>{return [...acc,...(item?.events?.map(d=>({...d,zone:item?.data})))]},[])
            array.push(['Danger Zone',cameraData.length||0])
        }
        if(!isHideConfinedSpace){
            array.push(['Confined Space',confineSpacePrintRef.current.obtainData()?.length||0])
        }

        const ws = utils.aoa_to_sheet(array);
        ws['!cols'] = [{ wch: 30 },{ wch: 30 }];
        utils.book_append_sheet(wb, ws, 'Summary');

        if(!isHideSmartCamera){
            let cameraData = cameraPrintRef.current.obtainData();
            const CAMERA_ALERT_TYPE_DICT={ 'CAMERA_DNG_ZONE':'Danger Zone', 'CAMERA_NO_JACKET':'No Jactket', 'CAMERA_NO_HELMET':'No Helmet', 'CAMERA_FALLDOWN':'Falldown'}
            const array=[
                ['Item','Camera Id',`Camera Name`,'Date & Time', 'Alert Type', 'Snapshot', 'Condition'],
            ]

            for(let index in cameraData){
                array.push([
                    Number(index)+1,
                    cameraData[index].deviceID,
                    cameraData[index].name,
                    cameraData[index].time?new Date(cameraData[index].time).toLocaleString():'',
                    CAMERA_ALERT_TYPE_DICT?.[cameraData[index].type]||'',
                    // cameraData[index]?.base64? { t: 's', v: 'Click to view', l: { Target: cameraData[index].base64, Tooltip: 'Image' } }:'',
                    cameraData[index]?.s3__?`=IMAGE("${cameraData[index]?.s3__}")`:"",
                    cameraData[index].condition||'Pending',
                ])
            }
            const ws = utils.aoa_to_sheet(array);
            ws['!cols'] = [{ wch: 10 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 }];
            utils.book_append_sheet(wb, ws, 'Camera');
        }

        if(!isHideSmartLock){
            let deviceData = lockPrintRef.current.obtainData();
            let dict = { "LOCK_UNLOCK":'Unlock', "LOCK_LOCK": "Lock", "LOCK_INAPR_UNLOCK": "Inappropriate unlock", "LOCK_VIBRATION": "Vibration", }
            const array=[
                ['Item','Serial Number',`Device Name`,'Event','Location','Date & Time','Condition'],
            ]

            for(let index in deviceData){
                array.push([
                    Number(index)+1,
                    deviceData[index].deviceID,
                    deviceData[index].name,
                    dict?.[deviceData[index].type]||'',
                   `lat: ${ deviceData[index].data?.lat},lng: ${ deviceData[index].data?.lng}`,
                    deviceData[index].time?new Date(deviceData[index].time).toLocaleString():'',
                    deviceData[index].condition||'Pending',
                ])
            }
            const ws = utils.aoa_to_sheet(array);
            ws['!cols'] = [{ wch: 10 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 }];
            utils.book_append_sheet(wb, ws, 'Smart Lock');
        }
        if(!isHideSmartWatch){
            let deviceData = watchPrintRef.current.obtainData();
            const dict = {"WATCH_SOS":'SOS Alert', "WATCH_HEARTRATE": "Heart Rate", "WATCH_BLD_OXYGEN": "Blood Oxygen", "WATCH_FALLDOWN": "Fall Alert", "WATCH_DNG_ZONE": "Danger Zone"}
            const array=[
                ['Item','Date & Time','Serial Number',`Name`,'Type','Location','Heart Rate','Blood Oxygen','Fall Alert','Condition'],
            ]

            for(let index in deviceData){
                array.push([
                    Number(index)+1,
                    deviceData[index].time?new Date(deviceData[index].time).toLocaleString():'',
                    deviceData[index].deviceID,
                    deviceData[index].name,
                    dict?.[deviceData[index].type]||'',
                   `lat: ${ deviceData[index].data?.Latitude + (isWatchDangerZone?(watchLatOffset||0):0)},lng: ${ deviceData[index].data?.Longitude + (isWatchDangerZone?(watchLngOffset||0):0)}`,
                    deviceData[index].data?.['Hr']||"~",
                    deviceData[index].data?.['Spo2']||"~",
                    deviceData[index].data?.['EventType']===11?'True':'~',
                    deviceData[index].condition||'Pending',
                ])
            }
            const ws = utils.aoa_to_sheet(array);
            ws['!cols'] = [{ wch: 10 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 }];
            utils.book_append_sheet(wb, ws, 'Smart Watch');
        }
        if(isWatchDangerZone){
            let deviceData = dangerZoneWatchPrintRef.current.obtainData();
            const array=[
                ['Item','Date & Time','Serial Number',`Name`,'Location','Condition'],
            ]

            for(let index in deviceData){
                array.push([
                    Number(index)+1,
                    deviceData[index].time?new Date(deviceData[index].time).toLocaleString():'',
                    deviceData[index].deviceID,
                    deviceData[index].name,
                   `lat: ${ deviceData[index].data?.Latitude + (watchLatOffset||0) },lng: ${ deviceData[index].data?.Longitude + (watchLngOffset||0) }`,
                    deviceData[index].condition||'Pending',
                ])
            }
            const ws = utils.aoa_to_sheet(array);
            ws['!cols'] = [{ wch: 10 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 }];
            utils.book_append_sheet(wb, ws, 'Danger Zone');
        }

        if((!isWatchDangerZone)&&(!isHideDangerZone)){
            let cameraData = dangerZonePrintRef.current.obtainData();
            cameraData= cameraData?.reduce((acc,item)=>{return [...acc,...(item?.events?.map(d=>({...d,zone:item?.data})))]},[])
            const CAMERA_ALERT_TYPE_DICT={ 'CAMERA_DNG_ZONE':'Danger Zone', 'CAMERA_NO_JACKET':'No Jactket', 'CAMERA_NO_HELMET':'No Helmet', 'CAMERA_FALLDOWN':'Falldown'}
            const array=[
                ['Item','Zone Id','Camera Id',`Camera Name`,'Date & Time', 'Alert Type', 'Snapshot'],
            ]

            for(let index in cameraData){
                array.push([
                    Number(index)+1,
                    cameraData[index].zone?.id||'',
                    cameraData[index].deviceID,
                    cameraData[index].name,
                    cameraData[index].time?new Date(cameraData[index].time).toLocaleString():'',
                    CAMERA_ALERT_TYPE_DICT?.[cameraData[index].type]||'',
                    // cameraData[index]?.base64? { t: 's', v: 'Click to view', l: { Target: cameraData[index].base64, Tooltip: 'Image' } }:'',
                    cameraData[index]?.s3__?`=IMAGE("${cameraData[index]?.s3__}")`:"",
                ])
            }
            const ws = utils.aoa_to_sheet(array);
            ws['!cols'] = [{ wch: 10 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 }];
            utils.book_append_sheet(wb, ws, 'Danger Zone');
        }

        if(!isHideConfinedSpace){
            const GAS_TYPE_DICT = {CS_O2_EXCEED_WARN: 'WARNING',CS_O2_EXCEED_EVACUATE: 'EVACUATE',CS_TEMPERATURE_WARN: 'WARNING',CS_TEMPERATURE_EVACUATE: 'EVACUATE',CS_PM25_EXCEED_WARN: 'WARNING',CS_PM25_EXCEED_EVACUATE: 'EVACUATE',CS_CO_EXCEED_WARN: 'WARNING',CS_CO_EXCEED_EVACUATE: 'EVACUATE',
                CS_CO2_EXCEED_WARN: 'WARNING',CS_CO2_EXCEED_EVACUATE: 'EVACUATE',CS_H2S_EXCEED_WARN: 'WARNING',CS_H2S_EXCEED_EVACUATE: 'EVACUATE',CS_CH4_EXCEED_WARN: 'WARNING',CS_CH4_EXCEED_EVACUATE: 'EVACUATE',CS_COM_GAS_EXCEED_WARN: 'WARNING',CS_COM_GAS_EXCEED_EVACUATE: 'EVACUATE',
            }
            
            const GAS_DICT = { CS_O2_EXCEED_WARN: 'O2', CS_O2_EXCEED_EVACUATE: 'O2', CS_TEMPERATURE_WARN: 'Temperature', CS_TEMPERATURE_EVACUATE: 'Temperature', CS_PM25_EXCEED_WARN: 'PM2.5',CS_PM25_EXCEED_EVACUATE: 'PM2.5',CS_CO_EXCEED_WARN: 'CO', CS_CO_EXCEED_EVACUATE: 'CO',
                CS_CO2_EXCEED_WARN: 'CO2',CS_CO2_EXCEED_EVACUATE: 'CO2',CS_H2S_EXCEED_WARN: 'H2S',CS_H2S_EXCEED_EVACUATE: 'H2S',CS_CH4_EXCEED_WARN: 'CH4',CS_CH4_EXCEED_EVACUATE: 'CH4',CS_COM_GAS_EXCEED_WARN: 'Compostable Gas',CS_COM_GAS_EXCEED_EVACUATE: 'Compostable Gas',
            }

            let deviceData = confineSpacePrintRef.current.obtainData();
            const array=[
                ['Item','Date & Time','Serial Number',`Name`,'Type','Gas'],
            ]

            for(let index in deviceData){
                array.push([
                    Number(index)+1,
                    deviceData[index].time?new Date(deviceData[index].time).toLocaleString():'',
                    deviceData[index].deviceID,
                    deviceData[index].name,
                    GAS_TYPE_DICT?.[deviceData[index]?.type]||'',
                    GAS_DICT?.[deviceData[index]?.type]||'',
                ])
            }

            const ws = utils.aoa_to_sheet(array);
            ws['!cols'] = [{ wch: 10 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 },{ wch: 30 }];
            utils.book_append_sheet(wb, ws, 'Confined Space');

        }

        writeFile(wb, 'Summary.xlsx');
        setisLoading(false)
        return
    }
    
    
    const downloadReport = async () => {
        if(isLoading)return
        // let cameraPrintDataArray = null
        // if(cameraPrintRef.current){
        //     cameraPrintDataArray = cameraPrintRef.current.print()
        // }
        // console.log('Total Camera Page: ', cameraPrintDataArray?.length)

        setisLoading(true)
        let doc = new jsPDF('p', 'mm', 'a4', true);

        let page =1;
        const arr=[]

        const d = divToPrintRef.current;

        //for(const d of cameraPrintDataArray||[]){
            console.log('adding page: ', page)

            const canvas = await html2canvas(d,{useCORS:true})
            const img= canvas.toDataURL('image/png');
            console.log('generated image for page: ', page)
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;
            arr.push([img, 'PNG', 0, position, imgWidth, imgHeight])
           
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                arr.push([img, 'PNG', 0, position, imgWidth, imgHeight])
                heightLeft -= pageHeight;
            }

            page++
        //}
        for(let i in arr){
            doc.addImage(arr[i][0], 'PNG', 0, arr[i][3], arr[i][4], arr[i][5], i, .5);
            if(i!=(arr.length-1))
                doc.addPage();
        }

        doc.save('file.pdf');
        setisLoading(false)
     };
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(['from','to'].includes(key)) searchParams.delete('date')
        if(key === 'date'){
            searchParams.delete('from')
            searchParams.delete('to') 
        }
        if(key === 'clearDate') {
            searchParams.delete('from')
            searchParams.delete('to')
            searchParams.delete('date')
            fromRef.current.value = ""
            toRef.current.value = ""
        }
        if(val !== '' && key !== 'clearDate') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function handleDateChange(val){
        if(val !== 'Select Date') return handleParamChange('date', val)
        setPopType('date')

    }

    //printing ref
    const cameraPrintRef = useRef(null);
    const lockPrintRef = useRef(null);
    const watchPrintRef = useRef(null);
    const dangerZonePrintRef = useRef(null);
    const dangerZoneWatchPrintRef = useRef(null);
    const confineSpacePrintRef = useRef(null);

    return(
        <div className={'w-full h-full bg-black relative main-window-lock ' + (hoverZoom ? ' overflow-hidden' : ' overflow-auto')}>

            <div style={{...LEFTRIGHT_PADDING}} className={'w-full py-[40px]'}>
                <div className="flex flex-row items-center">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">Summary</div>

                    <div className='flex flex-1 flex-row items-center text-white justify-end'>
                        <DropdownMenu value={(filterFrom && filterTo ? `${filterFrom} / ${filterTo}` :filterDate)} select = {handleDateChange}/>
                        <div onClick={()=>{setshowReportPreview(true)}} className='button_blue ml-2'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.0013 10.667L4.66797 7.33366L5.6013 6.36699L7.33464 8.10033V2.66699H8.66797V8.10033L10.4013 6.36699L11.3346 7.33366L8.0013 10.667ZM4.0013 13.3337C3.63464 13.3337 3.32075 13.2031 3.05964 12.942C2.79852 12.6809 2.66797 12.367 2.66797 12.0003V10.0003H4.0013V12.0003H12.0013V10.0003H13.3346V12.0003C13.3346 12.367 13.2041 12.6809 12.943 12.942C12.6819 13.2031 12.368 13.3337 12.0013 13.3337H4.0013Z" fill="#63BDFF"/>
                            </svg>
                            Download Report
                        </div>
                    </div>
                </div>
                <div>

                </div>
                <div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                    {/* <div className='rounded-[8px]' style={{flex:5,border:'.5px solid #aaa',padding:24}}>
                        <div className='w-full rounded-[8px]' style={{height:undefined,aspectRatio:11/8,minHeight:'100%',border:'.5px solid #aaa'}}>
                            <Home noPopup={true}/>
                        </div>
                    </div> */}
                    <div className='flex flex-row gap-[24px] w-full' style={{flex:2}}>
                        <div className='w-[50%] max-w-[450px] p-[24px] rounded-[8px]' style={{border:'.5px solid #aaa',}}>
                            {/* <TotalAlerts/> */}
                            <StatusPie />
                        </div>
                        <div className='' style={{width:`calc(100% - min(50%, 450px))`}}>
                            <div onMouseEnter={() => setHoverZoom(true)} onMouseLeave={() => setHoverZoom(false)} className='p-[24px] rounded-[8px] w-[100%]' style={{border:'.5px solid #aaa'}}>
                                <TrendCharts/>
                            </div>
                        </div>
                    </div>
                </div>
                {isHideSmartCamera||<div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                        <CameraAlert />
                    </div>
                </div>}
                {isHideSmartLock||<div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                        <SmartLockAlert />
                    </div>
                </div>}
                <div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                        <SmartWatchAlert />
                    </div>
                </div>
                {isWatchDangerZone&&<div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                        <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                            <DangerZoneWatchAlert/>
                        </div>
                    </div>}
                {(isHideDangerZone||isWatchDangerZone)||<div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                        <DangerZoneSum />
                    </div>
                </div>}
              
                {isHideConfinedSpace||<div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                        <ConfinedSummary />
                    </div>
                </div>}
            </div>
            

            {showReportPreview&&<div className='fixed inset-0 bg-[#00000070] flex justify-center items-center text-white' style={{filter: (props.control.theme===THEMES.DARK)?'none':'invert(1) hue-rotate(180deg)'}}>
                <div className='bg-[#00000070] relative max-h-[90%] w-[90%] max-w-[1920px] flex flex-col p-[24px] bg-[black] ' style={{border:'.5px solid #ffffff80'}}>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={()=>{setshowReportPreview(false)}}>X</div>         
                    <div>Report Preview</div>
                    <div className='bg-[#00000070] flex-1  mt-10 mb-10' style={{overflowY:'auto',overflowX:'hidden',width: '100%'}}>
                        <div ref={divToPrintRef} id="divToPrint" className="text-[#000] flex flex-col justify-start" style={{
                            width: '100%',
                            minHeight: '297px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            background: isPrintWhiteMode?'white':'black'
                            //filter: 'invert(1)'
                        }}>
                            <div style={{...LEFTRIGHT_PADDING}} className='w-full py-[40px]'>
                                {/* <img src='./cloudy.png' style={{filter: 'invert(1)'}}></img> */}
                                <div className="flex flex-row items-center">
                                    <TitleLeftSvg className='mr-[9px]' color={isPrintWhiteMode?'#000':'#fff'}/>
                                    <div className="text-[24px] font-bold " style={{color:isPrintWhiteMode?'#000':'#fff'}}>Summary</div>

                                    <div className='flex flex-1 flex-row items-center text-[#000] justify-end'>
                                    </div>
                                </div>
                                <div>

                                </div>
                                <div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                                    <div className='flex flex-row gap-[24px] w-full' style={{flex:2}}>
                                        <div className='w-[50%] max-w-[450px] p-[24px] rounded-[8px]' style={{border:'.5px solid #aaa'}}>
                                            {/* <TotalAlerts/> */}
                                            <StatusPie isReport={true} alertData={alertData}/>
                                        </div>
                                        <div className='' style={{width:`calc(100% - min(50%, 450px))`}}>
                                            <div className='p-[24px] rounded-[8px] w-[100%]' style={{border:'.5px solid #aaa'}}>
                                                <TrendCharts isReport={true}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isHideSmartCamera||<div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                                        <CameraAlert ref={cameraPrintRef} isReport={true} />
                                    </div>
                                </div>}
                               {isHideSmartLock||<div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                                        <SmartLockAlert ref={lockPrintRef} isReport={true}/>
                                    </div>
                                </div>}
                                <div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                                        <SmartWatchAlert ref={watchPrintRef}  isReport={true}/>
                                    </div>
                                </div>
                                {isWatchDangerZone&&<div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                                        <DangerZoneWatchAlert ref={dangerZoneWatchPrintRef} isReport={true}/>
                                    </div>
                                </div>}
                                {(isHideDangerZone||isWatchDangerZone)||<div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                                        <DangerZoneSum ref={dangerZonePrintRef} isReport={true}/>
                                    </div>
                                </div>}
                                {isHideConfinedSpace||<div className='w-full flex flex-row mt-5 items-stretch gap-[24px]'>
                                    <div className='w-full p-[24px] rounded-[8px] flex-1' style={{border:'.5px solid #aaa'}}>
                                        <ConfinedSummary ref={confineSpacePrintRef} isReport={true}/>
                                    </div>
                                </div>}
                            </div>
                            {/* {sfafsfdsadf} */}
                         </div>
                    </div>
                    <div className='flex flex-rows justify-center'>
                        <div onClick={downloadReport} className='py-[4px] px-[16px] rounded-[99px] flex flex-row items-center gap-[4px] bg-[#00202A] ml-[16px] text-[14px] text-[#63BDFF] cursor-pointer select-none'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.0013 10.667L4.66797 7.33366L5.6013 6.36699L7.33464 8.10033V2.66699H8.66797V8.10033L10.4013 6.36699L11.3346 7.33366L8.0013 10.667ZM4.0013 13.3337C3.63464 13.3337 3.32075 13.2031 3.05964 12.942C2.79852 12.6809 2.66797 12.367 2.66797 12.0003V10.0003H4.0013V12.0003H12.0013V10.0003H13.3346V12.0003C13.3346 12.367 13.2041 12.6809 12.943 12.942C12.6819 13.2031 12.368 13.3337 12.0013 13.3337H4.0013Z" fill="#63BDFF"/>
                            </svg>
                            Download Report (PDF)
                        </div>
                         <div onClick={downloadExcel} className='py-[4px] px-[16px] rounded-[99px] flex flex-row items-center gap-[4px] bg-[#00202A] ml-[16px] text-[14px] text-[#63BDFF] cursor-pointer select-none'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.0013 10.667L4.66797 7.33366L5.6013 6.36699L7.33464 8.10033V2.66699H8.66797V8.10033L10.4013 6.36699L11.3346 7.33366L8.0013 10.667ZM4.0013 13.3337C3.63464 13.3337 3.32075 13.2031 3.05964 12.942C2.79852 12.6809 2.66797 12.367 2.66797 12.0003V10.0003H4.0013V12.0003H12.0013V10.0003H13.3346V12.0003C13.3346 12.367 13.2041 12.6809 12.943 12.942C12.6819 13.2031 12.368 13.3337 12.0013 13.3337H4.0013Z" fill="#63BDFF"/>
                            </svg>
                            Download Report (Excel)
                        </div>
                    </div>
                </div>
            </div>}
            {popType === 'date' && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Select a date range*</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setPopType (false)}}>X</div>         
                    <div className='flex flex-row my-4 w-full justify-around'>
                        <div className='w-[50px]'>From:</div>   
                        <input type='date' ref={fromRef} defaultValue={filterFrom} max={filterTo || yearMonthDay(new Date())} onChange={(e) => {handleParamChange('from', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='flex flex-row mb-4 w-full justify-around'>
                        <div className='w-[50px]'>To:</div>   
                        <input type='date' ref={toRef} defaultValue={filterTo} min={filterFrom || undefined} onChange={(e) => {handleParamChange('to', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='w-full flex flex-row items-center justify-between gap-2'>
                        <div className='button_blue w-[50%]' onClick={() => {setPopType(false)}}>Confirm</div>
                        <div className='button_blue w-[50%] text-white' onClick={() => {handleParamChange('clearDate')}}>Clear</div>
                    </div>
                </div>
            </div>}

            {isLoading&&<LoadingPage/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Screen)