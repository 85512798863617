import { is3WSD18, isFLN, isSSM508, isHY202318, isFOOKLEEUNION, isTCM941, isTcj911, is2WSD23, is1858EM23T } from "../config"

export const ENABLE_DRAW_ZONE = (is3WSD18||is1858EM23T) ? false : true

export const DRAW_ZONE = 
is2WSD23?[
  {"lat":22.314486, "lng":114.135579},
  {"lat":22.315088, "lng":114.138486},
  {"lat":22.317947, "lng":114.137971},
  {"lat":22.318185, "lng":114.139087},
  {"lat":22.317393, "lng":114.140445},
  {"lat":22.316440, "lng":114.140960},
  {"lat":22.315884, "lng":114.141904},
  {"lat":22.315090, "lng":114.142247},
  {"lat":22.315090, "lng":114.142161},
  {"lat":22.316758, "lng":114.143277},
  {"lat":22.316758, "lng":114.143964},
  {"lat":22.317234, "lng":114.144307},
  {"lat":22.318028, "lng":114.144736},
  {"lat":22.319219, "lng":114.144135},
  {"lat":22.319854, "lng":114.144994},
  {"lat":22.321125, "lng":114.146281},
  {"lat":22.321462, "lng":114.147397},
  {"lat":22.322018, "lng":114.147612},
  {"lat":22.323561, "lng":114.146761},
  {"lat":22.324762, "lng":114.144596},
  {"lat":22.323905, "lng":114.145154},
  {"lat":22.323621, "lng":114.144902},
  {"lat":22.323185, "lng":114.145288},
  {"lat":22.323145, "lng":114.144945},
  {"lat":22.323463, "lng":114.144688},
  {"lat":22.322984, "lng":114.144148},
  {"lat":22.322591, "lng":114.144391},
  {"lat":22.322198, "lng":114.143784},
  {"lat":22.322815, "lng":114.143177},
  {"lat":22.322085, "lng":114.143602},
  {"lat":22.321636, "lng":114.143055},
  {"lat":22.322142, "lng":114.142327},
  {"lat":22.325005, "lng":114.143602},
  {"lat":22.326745, "lng":114.140628},
  {"lat":22.327812, "lng":114.141295},
  {"lat":22.326914, "lng":114.143055},
  {"lat":22.329440, "lng":114.144694},
  {"lat":22.329215, "lng":114.145240},
  {"lat":22.328710, "lng":114.144937},
  {"lat":22.323119, "lng":114.153105},
  {"lat":22.321884, "lng":114.152022},
  {"lat":22.321550, "lng":114.152527},
  {"lat":22.322752, "lng":114.153610},
  {"lat":22.322285, "lng":114.154223},
  {"lat":22.321081, "lng":114.154884},
  {"lat":22.320456, "lng":114.154895},
  {"lat":22.320392, "lng":114.156644},
  {"lat":22.321186, "lng":114.157174},
  {"lat":22.321245, "lng":114.157250},
  {"lat":22.322095, "lng":114.158590},
  {"lat":22.322850, "lng":114.159037},
  {"lat":22.323676, "lng":114.159062},
  {"lat":22.324514, "lng":114.158182},
  {"lat":22.324999, "lng":114.158614},
  {"lat":22.325027, "lng":114.161650},
  {"lat":22.326138, "lng":114.160384},
  {"lat":22.326779, "lng":114.174134},
  {"lat":22.326614, "lng":114.174696},
  {"lat":22.331610, "lng":114.176822},
  {"lat":22.334205, "lng":114.177078},
  {"lat":22.335001, "lng":114.176687},
  {"lat":22.334903, "lng":114.175632},
  {"lat":22.335591, "lng":114.175571},
  {"lat":22.334766, "lng":114.173442},
  {"lat":22.334907, "lng":114.172575},
  {"lat":22.335663, "lng":114.172626},
  {"lat":22.335619, "lng":114.170812},
  {"lat":22.334642, "lng":114.170210},
  {"lat":22.336838, "lng":114.170238},
  {"lat":22.338859, "lng":114.171512},
  {"lat":22.341216, "lng":114.170905},
  {"lat":22.341048, "lng":114.168235},
  {"lat":22.341272, "lng":114.161316},
  {"lat":22.344416, "lng":114.156521},
  {"lat":22.345258, "lng":114.147357},
  {"lat":22.348794, "lng":114.141895},
  {"lat":22.344640, "lng":114.135097},
  {"lat":22.340094, "lng":114.135765},
  {"lat":22.339364, "lng":114.133762},
  {"lat":22.339813, "lng":114.133155},
  {"lat":22.338746, "lng":114.128967},
  {"lat":22.337006, "lng":114.129635},
  {"lat":22.335434, "lng":114.124416},
  {"lat":22.328361, "lng":114.126601},
  {"lat":22.330662, "lng":114.134308},
  {"lat":22.327631, "lng":114.135219},
  {"lat":22.323928, "lng":114.122516},
  {"lat":22.322443, "lng":114.121524},
  {"lat":22.321378, "lng":114.121762},
  {"lat":22.317616, "lng":114.129258},
  {"lat":22.318093, "lng":114.131746},
  {"lat":22.316998, "lng":114.133415},
  {"lat":22.313686, "lng":114.134235},
  {"lat":22.314275, "lng":114.134386},
  {"lat":22.318205, "lng":114.133567},
  {"lat":22.318627, "lng":114.137330},
  {"lat":22.315174, "lng":114.138028},
  {"lat":22.314640, "lng":114.135327},
  {"lat":22.314486, "lng":114.135579},
]:
isTcj911?[
  {"lat": 22.285912, "lng": 114.143895, },
  {"lat": 22.285908, "lng": 114.144110, },
  {"lat": 22.285522, "lng": 114.144088, },
  {"lat": 22.285519, "lng": 114.143883, },
  {"lat": 22.285912, "lng": 114.143895, },
]:
isTCM941?[
  {"lat": 22.321566, "lng": 114.121649, },
  {"lat": 22.317411, "lng": 114.129174, },
  {"lat": 22.318322, "lng": 114.131162, },
  {"lat": 22.317360, "lng": 114.133242, },
  {"lat": 22.314061, "lng": 114.133985, },
  {"lat": 22.318322, "lng": 114.133539, },
  {"lat": 22.319010, "lng": 114.137254, },
  {"lat": 22.315298, "lng": 114.137997, },
  {"lat": 22.314749, "lng": 114.135322, },
  {"lat": 22.314474, "lng": 114.135322, },
  {"lat": 22.315298, "lng": 114.138442, },
  {"lat": 22.318047, "lng": 114.137848, },
  {"lat": 22.317910, "lng": 114.139185, },
  {"lat": 22.317635, "lng": 114.140225, },
  {"lat": 22.316673, "lng": 114.140671, },
  {"lat": 22.315028, "lng": 114.142384, },
  {"lat": 22.317687, "lng": 114.144508, },
  {"lat": 22.317918, "lng": 114.144883, },
  {"lat": 22.319305, "lng": 114.144508, },
  {"lat": 22.320345, "lng": 114.145633, },
  {"lat": 22.320923, "lng": 114.146132, },
  {"lat": 22.321245, "lng": 114.147454, },
  {"lat": 22.323343, "lng": 114.146968, },
  {"lat": 22.323643, "lng": 114.146806, },
  {"lat": 22.324542, "lng": 114.145347, },
  {"lat": 22.321844, "lng": 114.142917, },
  {"lat": 22.323343, "lng": 114.141297, },
  {"lat": 22.325142, "lng": 114.143403, },
  {"lat": 22.326791, "lng": 114.140973, },
  {"lat": 22.327690, "lng": 114.141621, },
  {"lat": 22.326791, "lng": 114.143079, },
  {"lat": 22.328907, "lng": 114.144632, },
  {"lat": 22.326152, "lng": 114.149100, },
  {"lat": 22.323324, "lng": 114.152647, },
  {"lat": 22.324874, "lng": 114.154604, },
  {"lat": 22.321522, "lng": 114.157418, },
  {"lat": 22.322159, "lng": 114.158431, },
  {"lat": 22.323283, "lng": 114.158978, },
  {"lat": 22.323714, "lng": 114.158816, },
  {"lat": 22.324445, "lng": 114.157843, },
  {"lat": 22.324745, "lng": 114.158087, },
  {"lat": 22.325063, "lng": 114.158795, },
  {"lat": 22.325138, "lng": 114.161044, },
  {"lat": 22.325382, "lng": 114.161165, },
  {"lat": 22.326168, "lng": 114.160132, },
  {"lat": 22.326626, "lng": 114.174253, },
  {"lat": 22.332068, "lng": 114.176693, },
  {"lat": 22.337122, "lng": 114.175617, },
  {"lat": 22.343253, "lng": 114.175018, },
  {"lat": 22.342598, "lng": 114.174331, },
  {"lat": 22.342380, "lng": 114.173666, },
  {"lat": 22.342220, "lng": 114.173795, },
  {"lat": 22.342537, "lng": 114.172850, },
  {"lat": 22.343093, "lng": 114.171820, },
  {"lat": 22.343252, "lng": 114.171048, },
  {"lat": 22.341029, "lng": 114.169160, },
  {"lat": 22.340394, "lng": 114.167443, },
  {"lat": 22.339997, "lng": 114.166070, },
  {"lat": 22.338806, "lng": 114.165212, },
  {"lat": 22.338806, "lng": 114.164267, },
  {"lat": 22.339270, "lng": 114.162469, },
  {"lat": 22.341166, "lng": 114.160344, },
  {"lat": 22.342636, "lng": 114.158223, },
  {"lat": 22.344434, "lng": 114.156456, },
  {"lat": 22.343780, "lng": 114.151686, },
  {"lat": 22.343944, "lng": 114.146915, },
  {"lat": 22.345293, "lng": 114.137391, },
  {"lat": 22.346305, "lng": 114.134528, },
  {"lat": 22.352351, "lng": 114.132596, },
  {"lat": 22.356871, "lng": 114.133205, },
  {"lat": 22.374372, "lng": 114.145414, },
  {"lat": 22.382616, "lng": 114.147791, },
  {"lat": 22.390858, "lng": 114.147494, },
  {"lat": 22.392085, "lng": 114.157226, },
  {"lat": 22.399386, "lng": 114.163062, },
  {"lat": 22.407956, "lng": 114.166152, },
  {"lat": 22.410813, "lng": 114.177825, },
  {"lat": 22.414622, "lng": 114.181945, },
  {"lat": 22.414304, "lng": 114.173362, },
  {"lat": 22.414622, "lng": 114.161002, },
  {"lat": 22.413035, "lng": 114.153106, },
  {"lat": 22.413352, "lng": 114.147613, },
  {"lat": 22.414622, "lng": 114.143150, },
  {"lat": 22.413987, "lng": 114.140060, },
  {"lat": 22.416526, "lng": 114.130447, },
  {"lat": 22.416209, "lng": 114.127700, },
  {"lat": 22.409226, "lng": 114.127700, },
  {"lat": 22.402243, "lng": 114.119460, },
  {"lat": 22.398751, "lng": 114.110534, },
  {"lat": 22.394942, "lng": 114.099891, },
  {"lat": 22.391133, "lng": 114.087188, },
  {"lat": 22.388276, "lng": 114.074828, },
  {"lat": 22.388276, "lng": 114.065902, },
  {"lat": 22.381927, "lng": 114.052856, },
  {"lat": 22.376530, "lng": 114.041183, },
  {"lat": 22.371768, "lng": 114.033973, },
  {"lat": 22.365736, "lng": 114.028136, },
  {"lat": 22.360338, "lng": 114.025047, },
  {"lat": 22.358096, "lng": 114.018237, },
  {"lat": 22.356032, "lng": 114.021842, },
  {"lat": 22.355397, "lng": 114.027678, },
  {"lat": 22.358096, "lng": 114.039180, },
  {"lat": 22.358414, "lng": 114.039523, },
  {"lat": 22.360039, "lng": 114.041155, },
  {"lat": 22.361360, "lng": 114.045442, },
  {"lat": 22.360794, "lng": 114.048096, },
  {"lat": 22.362021, "lng": 114.051260, },
  {"lat": 22.362210, "lng": 114.053097, },
  {"lat": 22.364475, "lng": 114.055343, },
  {"lat": 22.366363, "lng": 114.060038, },
  {"lat": 22.365419, "lng": 114.060855, },
  {"lat": 22.365419, "lng": 114.063100, },
  {"lat": 22.364192, "lng": 114.064223, },
  {"lat": 22.364003, "lng": 114.065958, },
  {"lat": 22.363342, "lng": 114.068000, },
  {"lat": 22.363342, "lng": 114.068000, },
  {"lat": 22.363997, "lng": 114.069453, },
  {"lat": 22.363600, "lng": 114.070870, },
  {"lat": 22.363997, "lng": 114.071256, },
  {"lat": 22.364538, "lng": 114.070975, },
  {"lat": 22.365198, "lng": 114.071383, },
  {"lat": 22.367267, "lng": 114.074491, },
  {"lat": 22.366870, "lng": 114.078353, },
  {"lat": 22.367664, "lng": 114.079641, },
  {"lat": 22.368688, "lng": 114.079326, },
  {"lat": 22.369217, "lng": 114.080584, },
  {"lat": 22.367645, "lng": 114.084499, },
  {"lat": 22.367249, "lng": 114.091696, },
  {"lat": 22.368147, "lng": 114.095216, },
  {"lat": 22.368035, "lng": 114.097218, },
  {"lat": 22.368540, "lng": 114.099161, },
  {"lat": 22.372132, "lng": 114.103895, },
  {"lat": 22.372019, "lng": 114.105533, },
  {"lat": 22.369728, "lng": 114.107806, },
  {"lat": 22.368478, "lng": 114.108186, },
  {"lat": 22.367534, "lng": 114.109360, },
  {"lat": 22.366540, "lng": 114.110954, },
  {"lat": 22.364788, "lng": 114.112307, },
  {"lat": 22.364254, "lng": 114.112343, },
  {"lat": 22.361915, "lng": 114.111343, },
  {"lat": 22.359335, "lng": 114.111858, },
  {"lat": 22.359256, "lng": 114.112287, },
  {"lat": 22.359891, "lng": 114.112544, },
  {"lat": 22.360129, "lng": 114.113231, },
  {"lat": 22.354594, "lng": 114.116158, },
  {"lat": 22.351924, "lng": 114.115725, },
  {"lat": 22.351724, "lng": 114.115003, },
  {"lat": 22.353125, "lng": 114.114390, },
  {"lat": 22.352558, "lng": 114.113596, },
  {"lat": 22.347251, "lng": 114.115725, },
  {"lat": 22.348286, "lng": 114.119911, },
  {"lat": 22.338293, "lng": 114.123383, },
  {"lat": 22.339614, "lng": 114.128691, },
  {"lat": 22.337138, "lng": 114.129509, },
  {"lat": 22.335402, "lng": 114.124096, },
  {"lat": 22.328392, "lng": 114.126622, },
  {"lat": 22.330528, "lng": 114.134128, },
  {"lat": 22.327658, "lng": 114.135211, },
  {"lat": 22.323922, "lng": 114.122126, },
  {"lat": 22.321566, "lng": 114.121649, },  
]:
isFOOKLEEUNION?[
  {"lat": 22.344510, "lng": 114.153683, },
  {"lat": 22.363391, "lng": 114.164884, },
  {"lat": 22.356702, "lng": 114.166763, },
  {"lat": 22.359123, "lng": 114.178496, },
  {"lat": 22.367096, "lng": 114.190736, },
  {"lat": 22.367086, "lng": 114.202404, },
  {"lat": 22.372612, "lng": 114.211406, },
  {"lat": 22.370489, "lng": 114.215656, },
  {"lat": 22.363140, "lng": 114.215307, },
  {"lat": 22.357473, "lng": 114.212609, },
  {"lat": 22.347290, "lng": 114.211222, },
  {"lat": 22.340690, "lng": 114.213741, },
  {"lat": 22.338392, "lng": 114.219144, },
  {"lat": 22.333903, "lng": 114.221209, },
  {"lat": 22.332225, "lng": 114.213936, },
  {"lat": 22.334408, "lng": 114.211195, },
  {"lat": 22.334408, "lng": 114.211195, },
  {"lat": 22.332543, "lng": 114.207068, },
  {"lat": 22.335328, "lng": 114.202797, },
  {"lat": 22.332502, "lng": 114.196638, },
  {"lat": 22.334702, "lng": 114.194141, },
  {"lat": 22.336106, "lng": 114.194515, },
  {"lat": 22.336839, "lng": 114.191927, },
  {"lat": 22.337299, "lng": 114.190392, },
  {"lat": 22.335806, "lng": 114.187495, },
  {"lat": 22.334753, "lng": 114.187427, },
  {"lat": 22.334753, "lng": 114.187427, },
  {"lat": 22.337619, "lng": 114.184269, },
  {"lat": 22.338699, "lng": 114.185181, },
  {"lat": 22.339594, "lng": 114.183822, },
  {"lat": 22.339446, "lng": 114.182944, },
  {"lat": 22.343014, "lng": 114.184494, },
  {"lat": 22.346670, "lng": 114.180938, },
  {"lat": 22.344110, "lng": 114.171786, },
  {"lat": 22.346127, "lng": 114.162310, },
  {"lat": 22.341255, "lng": 114.161279, },
  {"lat": 22.342374, "lng": 114.160590, },
  {"lat": 22.344439, "lng": 114.156627, }
]:
isHY202318?[
  {"lat": 22.250232, "lng":114.172542},
  {"lat": 22.249830, "lng":114.173987},
  {"lat": 22.249386, "lng":114.174893},
  {"lat": 22.249242, "lng":114.174831},
  {"lat": 22.249697, "lng":114.173957},
  {"lat": 22.250055, "lng":114.172482},
]:
isSSM508?
[
  {"lat": 22.276735, "lng": 114.179534, },
  {"lat": 22.276839, "lng": 114.179880, },
  {"lat": 22.276584, "lng": 114.179966, },
  {"lat": 22.276239, "lng": 114.179933, },
  {"lat": 22.276137, "lng": 114.179874, },
  {"lat": 22.276273, "lng": 114.179574, },
  {"lat": 22.276574, "lng": 114.179590, },
  {"lat": 22.276735, "lng": 114.179534, },
]
:isFLN?
[
  {"lat": 22.485320,"lng": 114.151773, },
  {"lat": 22.485596,"lng": 114.151995, },
  {"lat": 22.484993,"lng": 114.152720, },
  {"lat": 22.484499,"lng": 114.153154, },
  {"lat": 22.483582,"lng": 114.153860, },
  {"lat": 22.482611,"lng": 114.154162, },
  {"lat": 22.482534,"lng": 114.153827, },
  {"lat": 22.483744,"lng": 114.153329, },
  {"lat": 22.484352,"lng": 114.152860, },
  {"lat": 22.484765,"lng": 114.152452, },
  {"lat": 22.485067,"lng": 114.152162, },
  {"lat": 22.485320,"lng": 114.151773, },
]:[
  { "lng": 114.17507836, "lat": 22.30111762, },
  { "lng": 114.17807836, "lat": 22.30181762, },
  { "lng": 114.17925836, "lat": 22.30060762, },
  { "lng": 114.17689836, "lat": 22.29901762, },
  { "lng": 114.17599836, "lat": 22.29971762, },
  { "lng": 114.17459836, "lat": 22.29951762, },
]

export const DRAW_ZONE_2 = 
is2WSD23?[
  {"lat":22.254241, "lng":114.186479,},
  {"lat":22.261231, "lng":114.188367,},
  {"lat":22.265044, "lng":114.179097,},
  {"lat":22.266632, "lng":114.177724,},
  {"lat":22.271398, "lng":114.176522,},
  {"lat":22.272669, "lng":114.174977,},
  {"lat":22.273463, "lng":114.174977,},
  {"lat":22.272828, "lng":114.173089,},
  {"lat":22.273940, "lng":114.172403,},
  {"lat":22.275052, "lng":114.170514,},
  {"lat":22.274575, "lng":114.169484,},
  {"lat":22.275687, "lng":114.166566,},
  {"lat":22.278444, "lng":114.167094,},
  {"lat":22.279575, "lng":114.166480,},
  {"lat":22.281346, "lng":114.168113,},
  {"lat":22.281487, "lng":114.168674,},
  {"lat":22.282880, "lng":114.169006,},
  {"lat":22.284495, "lng":114.163151,},
  {"lat":22.286431, "lng":114.162051,},
  {"lat":22.288390, "lng":114.155784,},
  {"lat":22.289002, "lng":114.155881,},
  {"lat":22.287459, "lng":114.154272,},
  {"lat":22.288589, "lng":114.150572,},
  {"lat":22.289507, "lng":114.149844,},
  {"lat":22.290732, "lng":114.140512,},
  {"lat":22.289324, "lng":114.134357,},
  {"lat":22.289752, "lng":114.131710,},
  {"lat":22.289262, "lng":114.131710,},
  {"lat":22.288711, "lng":114.134622,},
  {"lat":22.287849, "lng":114.134730,},
  {"lat":22.288210, "lng":114.132365,},
  {"lat":22.284119, "lng":114.127487,},
  {"lat":22.281424, "lng":114.118829,},
  {"lat":22.279076, "lng":114.117255,},
  {"lat":22.278362, "lng":114.126997,},
  {"lat":22.278971, "lng":114.131076,},
  {"lat":22.280228, "lng":114.131372,},
  {"lat":22.280847, "lng":114.130014,},
  {"lat":22.281673, "lng":114.130419,},
  {"lat":22.281296, "lng":114.130870,},
  {"lat":22.281604, "lng":114.132458,},
  {"lat":22.282024, "lng":114.132581,},
  {"lat":22.282430, "lng":114.132424,},
  {"lat":22.283397, "lng":114.132805,},
  {"lat":22.283744, "lng":114.131851,},
  {"lat":22.284203, "lng":114.132022,},
  {"lat":22.284178, "lng":114.132580,},
  {"lat":22.284299, "lng":114.133198,},
  {"lat":22.284086, "lng":114.133511,},
  {"lat":22.284304, "lng":114.134061,},
  {"lat":22.284307, "lng":114.135463,},
  {"lat":22.284032, "lng":114.135654,},
  {"lat":22.283850, "lng":114.135494,},
  {"lat":22.283535, "lng":114.136266,},
  {"lat":22.283579, "lng":114.136569,},
  {"lat":22.284877, "lng":114.137333,},
  {"lat":22.284694, "lng":114.138371,},
  {"lat":22.284015, "lng":114.138656,},
  {"lat":22.283930, "lng":114.139118,},
  {"lat":22.284313, "lng":114.139809,},
  {"lat":22.284060, "lng":114.140431,},
  {"lat":22.283471, "lng":114.140644,},
  {"lat":22.281252, "lng":114.140629,},
  {"lat":22.280521, "lng":114.137905,},
  {"lat":22.278499, "lng":114.138755,},
  {"lat":22.272729, "lng":114.136497,},
  {"lat":22.262739, "lng":114.138582,},
  {"lat":22.257670, "lng":114.149536,},
  {"lat":22.259632, "lng":114.162082,},
  {"lat":22.258896, "lng":114.166941,},
  {"lat":22.255707, "lng":114.170916,},
  {"lat":22.255871, "lng":114.180104,},
  {"lat":22.254399, "lng":114.186642,},
  {"lat":22.254241, "lng":114.186479,},
]:isTCM941?[
  {"lat": 22.363912, "lng": 114.107353, },
{"lat": 22.356898, "lng": 114.109128, },
{"lat": 22.352294, "lng": 114.110072, },
{"lat": 22.350786, "lng": 114.110759, },
{"lat": 22.348484, "lng": 114.112390, },
{"lat": 22.333621, "lng": 114.117305, },
{"lat": 22.331001, "lng": 114.117477, },
{"lat": 22.327428, "lng": 114.114044, },
{"lat": 22.327269, "lng": 114.112499, },
{"lat": 22.328778, "lng": 114.112584, },
{"lat": 22.328540, "lng": 114.110181, },
{"lat": 22.327269, "lng": 114.110439, },
{"lat": 22.326872, "lng": 114.105890, },
{"lat": 22.328302, "lng": 114.103916, },
{"lat": 22.327190, "lng": 114.095332, },
{"lat": 22.326079, "lng": 114.095075, },
{"lat": 22.326079, "lng": 114.091813, },
{"lat": 22.328937, "lng": 114.090183, },
{"lat": 22.330763, "lng": 114.088981, },
{"lat": 22.337987, "lng": 114.092328, },
{"lat": 22.338384, "lng": 114.092586, },
{"lat": 22.339178, "lng": 114.091127, },
{"lat": 22.339020, "lng": 114.090440, },
{"lat": 22.339880, "lng": 114.089695, },
{"lat": 22.341003, "lng": 114.089512, },
{"lat": 22.342066, "lng": 114.091239, },
{"lat": 22.343624, "lng": 114.089912, },
{"lat": 22.344497, "lng": 114.084221, },
{"lat": 22.348349, "lng": 114.084669, },
{"lat": 22.348904, "lng": 114.083039, },
{"lat": 22.351048, "lng": 114.083468, },
{"lat": 22.351127, "lng": 114.082695, },
{"lat": 22.348190, "lng": 114.082438, },
{"lat": 22.348428, "lng": 114.081665, },
{"lat": 22.350889, "lng": 114.082180, },
{"lat": 22.352397, "lng": 114.080034, },
{"lat": 22.354858, "lng": 114.079777, },
{"lat": 22.355255, "lng": 114.080292, },
{"lat": 22.356525, "lng": 114.080206, },
{"lat": 22.357160, "lng": 114.079434, },
{"lat": 22.358250, "lng": 114.078986, },
{"lat": 22.359618, "lng": 114.080177, },
{"lat": 22.360017, "lng": 114.080896, },
{"lat": 22.360217, "lng": 114.082286, },
{"lat": 22.361802, "lng": 114.085461, },
{"lat": 22.362078, "lng": 114.087285, },
{"lat": 22.362386, "lng": 114.087886, },
{"lat": 22.362865, "lng": 114.089615, },
{"lat": 22.361911, "lng": 114.089919, },
{"lat": 22.361883, "lng": 114.096382, },
{"lat": 22.363707, "lng": 114.099629, },
{"lat": 22.363841, "lng": 114.100259, },
{"lat": 22.363645, "lng": 114.103901, },
{"lat": 22.363195, "lng": 114.107512, },
]:
isFOOKLEEUNION?[
  {"lat": 22.359735, "lng": 114.140818, },
  {"lat": 22.384460, "lng": 114.154910, },
  {"lat": 22.386197, "lng": 114.169021, },
  {"lat": 22.384226, "lng": 114.173840, },
  {"lat": 22.391890, "lng": 114.181885, },
  {"lat": 22.396999, "lng": 114.176541, },
  {"lat": 22.416616, "lng": 114.199641, },
  {"lat": 22.416958, "lng": 114.199098, },
  {"lat": 22.427422, "lng": 114.193793, },
  {"lat": 22.428743, "lng": 114.204114, },
  {"lat": 22.423017, "lng": 114.212683, },
  {"lat": 22.415251, "lng": 114.215062, },
  {"lat": 22.410700, "lng": 114.213710, },
  {"lat": 22.406387, "lng": 114.219470, },
  {"lat": 22.428039, "lng": 114.228308, },
  {"lat": 22.427802, "lng": 114.233654, },
  {"lat": 22.435894, "lng": 114.236990, },
  {"lat": 22.434632, "lng": 114.267835, },
  {"lat": 22.423398, "lng": 114.271364, },
  {"lat": 22.406748, "lng": 114.276412, },
  {"lat": 22.403370, "lng": 114.260579, },
  {"lat": 22.371834, "lng": 114.248152, },
  {"lat": 22.353506, "lng": 114.227771, },
  {"lat": 22.357515, "lng": 114.220398, },
  {"lat": 22.357610, "lng": 114.212717, },
  {"lat": 22.370370, "lng": 114.215553, },
  {"lat": 22.372483, "lng": 114.211115, },
  {"lat": 22.366990, "lng": 114.202463, },
  {"lat": 22.367078, "lng": 114.190586, },
  {"lat": 22.355522, "lng": 114.176379, },
  {"lat": 22.363261, "lng": 114.164858, },
  {"lat": 22.344565, "lng": 114.153721, },
  {"lat": 22.348452, "lng": 114.142114, },]:
  isHY202318?[
    { "lat":22.314254, "lng":114.257555 },
    { "lat":22.314387, "lng":114.257810 },
    { "lat":22.314484, "lng":114.257951 },
    { "lat":22.314580, "lng":114.257868 },
    { "lat":22.314563, "lng":114.257958 },
    { "lat":22.314497, "lng":114.258066 },
    { "lat":22.314386, "lng":114.257952 },
    { "lat":22.314200, "lng":114.257641 },
  ]:null

export const DRAW_ZONE_3_COLOR_1 = (isFOOKLEEUNION)?'#0057FF':null
export const DRAW_ZONE_3_COLOR_2 = (isFOOKLEEUNION)?'#0057FF80':null
export const DRAW_ZONE_3 = 
is2WSD23?[
  {"lat":22.257196, "lng":114.256302},
{"lat":22.259443, "lng":114.254845},
{"lat":22.261240, "lng":114.255695},
{"lat":22.261690, "lng":114.257516},
{"lat":22.269497, "lng":114.248837},
{"lat":22.268072, "lng":114.247439},
{"lat":22.268991, "lng":114.246627},
{"lat":22.273234, "lng":114.245376},
{"lat":22.273777, "lng":114.244918},
{"lat":22.273630, "lng":114.244845},
{"lat":22.272142, "lng":114.245610},
{"lat":22.271524, "lng":114.245004},
{"lat":22.269502, "lng":114.245550},
{"lat":22.268138, "lng":114.243651},
{"lat":22.268280, "lng":114.243064},
{"lat":22.269862, "lng":114.241788},
{"lat":22.272719, "lng":114.244340},
{"lat":22.276053, "lng":114.241151},
{"lat":22.281663, "lng":114.238841},
{"lat":22.284401, "lng":114.233789},
{"lat":22.283132, "lng":114.226355},
{"lat":22.284535, "lng":114.224695},
{"lat":22.285269, "lng":114.225633},
{"lat":22.288341, "lng":114.222313},
{"lat":22.292238, "lng":114.210477},
{"lat":22.293975, "lng":114.202971},
{"lat":22.292639, "lng":114.195609},
{"lat":22.289834, "lng":114.191711},
{"lat":22.290769, "lng":114.191134},
{"lat":22.288231, "lng":114.188824},
{"lat":22.286585, "lng":114.191494},
{"lat":22.286987, "lng":114.192996},
{"lat":22.284803, "lng":114.192846},
{"lat":22.284519, "lng":114.194290},
{"lat":22.280282, "lng":114.193257},
{"lat":22.279661, "lng":114.193668},
{"lat":22.279949, "lng":114.194452},
{"lat":22.278738, "lng":114.196061},
{"lat":22.281732, "lng":114.205994},
{"lat":22.279485, "lng":114.209817},
{"lat":22.278924, "lng":114.215340},
{"lat":22.280149, "lng":114.217513},
{"lat":22.276099, "lng":114.220259},
{"lat":22.270856, "lng":114.231417},
{"lat":22.262834, "lng":114.225323},
{"lat":22.262040, "lng":114.225753},
{"lat":22.258147, "lng":114.225495},
{"lat":22.257432, "lng":114.228070},
{"lat":22.256320, "lng":114.229872},
{"lat":22.257274, "lng":114.232447},
{"lat":22.256559, "lng":114.232962},
{"lat":22.258068, "lng":114.236911},
{"lat":22.256559, "lng":114.240687},
{"lat":22.260530, "lng":114.244206},
{"lat":22.256419, "lng":114.248964},
{"lat":22.256658, "lng":114.256163},
{"lat":22.257196, "lng":114.256302},
]:
isTCM941?[
{"lat": 22.345663, "lng": 114.066104, },
{"lat": 22.344954, "lng": 114.064512, },
{"lat": 22.344300, "lng": 114.064512, },
{"lat": 22.343591, "lng": 114.062743, },
{"lat": 22.343427, "lng": 114.061446, },
{"lat": 22.342882, "lng": 114.060797, },
{"lat": 22.342555, "lng": 114.059323, },
{"lat": 22.343755, "lng": 114.058498, },
{"lat": 22.344464, "lng": 114.057908, },
{"lat": 22.344765, "lng": 114.057003, },
{"lat": 22.346240, "lng": 114.056680, },
{"lat": 22.346925, "lng": 114.056701, },
{"lat": 22.347522, "lng": 114.056888, },
{"lat": 22.347677, "lng": 114.057858, },
{"lat": 22.348149, "lng": 114.057712, },
{"lat": 22.348188, "lng": 114.056534, },
{"lat": 22.348361, "lng": 114.055909, },
{"lat": 22.348178, "lng": 114.055460, },
{"lat": 22.348678, "lng": 114.053960, },
{"lat": 22.349985, "lng": 114.055150, },
{"lat": 22.350100, "lng": 114.056365, },
{"lat": 22.352255, "lng": 114.057481, },
{"lat": 22.352783, "lng": 114.057456, },
{"lat": 22.353654, "lng": 114.056911, },
{"lat": 22.353975, "lng": 114.056216, },
{"lat": 22.355328, "lng": 114.056539, },
{"lat": 22.355603, "lng": 114.056911, },
{"lat": 22.355626, "lng": 114.057977, },
{"lat": 22.355809, "lng": 114.058993, },
{"lat": 22.355878, "lng": 114.059960, },
{"lat": 22.355695, "lng": 114.060629, },
{"lat": 22.355076, "lng": 114.061001, },
{"lat": 22.354777, "lng": 114.062092, },
{"lat": 22.355144, "lng": 114.063034, },
{"lat": 22.355534, "lng": 114.063282, },
{"lat": 22.355947, "lng": 114.064695, },
{"lat": 22.355328, "lng": 114.065266, },
{"lat": 22.354319, "lng": 114.064596, },
{"lat": 22.353952, "lng": 114.064101, },
{"lat": 22.353792, "lng": 114.063481, },
{"lat": 22.352920, "lng": 114.063431, },
{"lat": 22.353425, "lng": 114.064894, },
{"lat": 22.352874, "lng": 114.065092, },
{"lat": 22.352462, "lng": 114.064150, },
{"lat": 22.351957, "lng": 114.062911, },
{"lat": 22.351476, "lng": 114.061944, },
{"lat": 22.351040, "lng": 114.061795, },
{"lat": 22.351040, "lng": 114.061795, },
{"lat": 22.348770, "lng": 114.062390, },
{"lat": 22.349160, "lng": 114.064646, },
{"lat": 22.347830, "lng": 114.064572, },
{"lat": 22.346428, "lng": 114.065041, },
{"lat": 22.346230, "lng": 114.065159, },
{"lat": 22.346418, "lng": 114.065964, },
{"lat": 22.345624, "lng": 114.066210, },
]:
isFOOKLEEUNION?
[
  {"lat": 22.341809, "lng":114.257464, },
  {"lat": 22.345441, "lng":114.254724, },
  {"lat": 22.346542, "lng":114.252667, },
  {"lat": 22.342537, "lng":114.236644, },
  {"lat": 22.353551, "lng":114.227947, },
  {"lat": 22.360858, "lng":114.232202, },
  {"lat": 22.372672, "lng":114.247719, },
  {"lat": 22.403392, "lng":114.260629, },
  {"lat": 22.406479, "lng":114.276577, },
  {"lat": 22.409315, "lng":114.276216, },
  {"lat": 22.409513, "lng":114.276518, },
  {"lat": 22.410167, "lng":114.276314, },
  {"lat": 22.410380, "lng":114.276289, },
  {"lat": 22.410763, "lng":114.276091, },
  {"lat": 22.411017, "lng":114.276117, },
  {"lat": 22.411270, "lng":114.275944, },
  {"lat": 22.411371, "lng":114.275944, },
  {"lat": 22.411447, "lng":114.275989, },
  {"lat": 22.411553, "lng":114.275976, },
  {"lat": 22.411718, "lng":114.275696, },
  {"lat": 22.411901, "lng":114.275734, },
  {"lat": 22.411945, "lng":114.275648, },
  {"lat": 22.412046, "lng":114.275635, },
  {"lat": 22.412179, "lng":114.275684, },
  {"lat": 22.412755, "lng":114.276036, },
  {"lat": 22.412807, "lng":114.276095, },
  {"lat": 22.413129, "lng":114.276137, },
  {"lat": 22.414002, "lng":114.275670, },
  {"lat": 22.414269, "lng":114.275374, },
  {"lat": 22.414795, "lng":114.275196, },
  {"lat": 22.415034, "lng":114.275168, },
  {"lat": 22.415284, "lng":114.274314, },
  {"lat": 22.415344, "lng":114.274337, },
  {"lat": 22.415204, "lng":114.274863, },
  {"lat": 22.415136, "lng":114.275261, },
  {"lat": 22.415262, "lng":114.275250, },
  {"lat": 22.415289, "lng":114.275386, },
  {"lat": 22.415439, "lng":114.275342, },
  {"lat": 22.415644, "lng":114.275346, },
  {"lat": 22.416636, "lng":114.275859, },
  {"lat": 22.417523, "lng":114.275980, },
  {"lat": 22.418281, "lng":114.275995, },
  {"lat": 22.418408, "lng":114.275990, },
  {"lat": 22.418422, "lng":114.275855, },
  {"lat": 22.418557, "lng":114.275844, },
  {"lat": 22.418721, "lng":114.275954, },
  {"lat": 22.418924, "lng":114.276112, },
  {"lat": 22.419044, "lng":114.276086, },
  {"lat": 22.419097, "lng":114.276247, },
  {"lat": 22.419059, "lng":114.276492, },
  {"lat": 22.419357, "lng":114.277587, },
  {"lat": 22.419333, "lng":114.277821, },
  {"lat": 22.419507, "lng":114.278405, },
  {"lat": 22.419574, "lng":114.278494, },
  {"lat": 22.419719, "lng":114.278520, },
  {"lat": 22.419825, "lng":114.278619, },
  {"lat": 22.419878, "lng":114.278770, },
  {"lat": 22.419882, "lng":114.278761, },
  {"lat": 22.419877, "lng":114.279029, },
  {"lat": 22.420417, "lng":114.279464, },
  {"lat": 22.420606, "lng":114.279871, },
  {"lat": 22.421087, "lng":114.280563, },
  {"lat": 22.421216, "lng":114.280708, },
  {"lat": 22.421355, "lng":114.280735, },
  {"lat": 22.421751, "lng":114.282055, },
  {"lat": 22.422704, "lng":114.283353, },
  {"lat": 22.422828, "lng":114.283439, },
  {"lat": 22.423001, "lng":114.283423, },
  {"lat": 22.423199, "lng":114.283642, },
  {"lat": 22.423224, "lng":114.283814, },
  {"lat": 22.423343, "lng":114.284023, },
  {"lat": 22.423259, "lng":114.284163, },
  {"lat": 22.423209, "lng":114.284351, },
  {"lat": 22.423512, "lng":114.284506, },
  {"lat": 22.424390, "lng":114.284923, },
  {"lat": 22.424915, "lng":114.285542, },
  {"lat": 22.424944, "lng":114.286110, },
  {"lat": 22.425198, "lng":114.286824, },
  {"lat": 22.425227, "lng":114.287642, },
  {"lat": 22.425491, "lng":114.287835, },
  {"lat": 22.425436, "lng":114.287685, },
  {"lat": 22.425516, "lng":114.287508, },
  {"lat": 22.425893, "lng":114.287347, },
  {"lat": 22.426022, "lng":114.287352, },
  {"lat": 22.426250, "lng":114.287481, },
  {"lat": 22.426428, "lng":114.287819, },
  {"lat": 22.426413, "lng":114.287964, },
  {"lat": 22.426393, "lng":114.288173, },
  {"lat": 22.426557, "lng":114.288457, },
  {"lat": 22.427326, "lng":114.288613, },
  {"lat": 22.427673, "lng":114.288548, },
  {"lat": 22.427762, "lng":114.288682, },
  {"lat": 22.427856, "lng":114.288720, },
  {"lat": 22.427916, "lng":114.288870, },
  {"lat": 22.428094, "lng":114.288929, },
  {"lat": 22.428213, "lng":114.289289, },
  {"lat": 22.428491, "lng":114.289589, },
  {"lat": 22.428506, "lng":114.289745, },
  {"lat": 22.428655, "lng":114.289610, },
  {"lat": 22.428729, "lng":114.289272, },
  {"lat": 22.428888, "lng":114.289079, },
  {"lat": 22.429022, "lng":114.288849, },
  {"lat": 22.429289, "lng":114.288672, },
  {"lat": 22.429294, "lng":114.288500, },
  {"lat": 22.429180, "lng":114.288200, },
  {"lat": 22.429170, "lng":114.287588, },
  {"lat": 22.429284, "lng":114.287363, },
  {"lat": 22.429349, "lng":114.286982, },
  {"lat": 22.429508, "lng":114.286660, },
  {"lat": 22.429428, "lng":114.285925, },
  {"lat": 22.429914, "lng":114.285592, },
  {"lat": 22.430534, "lng":114.285619, },
  {"lat": 22.430579, "lng":114.285528, },
  {"lat": 22.430603, "lng":114.285351, },
  {"lat": 22.430975, "lng":114.285179, },
  {"lat": 22.431783, "lng":114.284187, },
  {"lat": 22.432493, "lng":114.283774, },
  {"lat": 22.433324, "lng":114.283573, },
  {"lat": 22.435059, "lng":114.283683, },
  {"lat": 22.435421, "lng":114.283522, },
  {"lat": 22.435783, "lng":114.283076, },
  {"lat": 22.435872, "lng":114.282680, },
  {"lat": 22.436026, "lng":114.282535, },
  {"lat": 22.436849, "lng":114.282250, },
  {"lat": 22.437390, "lng":114.282261, },
  {"lat": 22.438363, "lng":114.281865, },
  {"lat": 22.438771, "lng":114.281495, },
  {"lat": 22.438996, "lng":114.281423, },
  {"lat": 22.439847, "lng":114.281450, },
  {"lat": 22.440464, "lng":114.281739, },
  {"lat": 22.440546, "lng":114.281673, },
  {"lat": 22.440575, "lng":114.281794, },
  {"lat": 22.440493, "lng":114.281871, },
  {"lat": 22.440798, "lng":114.282238, },
  {"lat": 22.441058, "lng":114.282736, },
  {"lat": 22.441541, "lng":114.283170, },
  {"lat": 22.441871, "lng":114.284012, },
  {"lat": 22.443593, "lng":114.283897, },
  {"lat": 22.443876, "lng":114.282595, },
  {"lat": 22.443805, "lng":114.282072, },
  {"lat": 22.443864, "lng":114.281906, },
  {"lat": 22.443463, "lng":114.281677, },
  {"lat": 22.443463, "lng":114.281677, },
  {"lat": 22.444054, "lng":114.280301, },
  {"lat": 22.444021, "lng":114.279950, },
  {"lat": 22.444088, "lng":114.279192, },
  {"lat": 22.444230, "lng":114.279039, },
  {"lat": 22.444246, "lng":114.278867, },
  {"lat": 22.444296, "lng":114.278678, },
  {"lat": 22.444213, "lng":114.278542, },
  {"lat": 22.444104, "lng":114.278524, },
  {"lat": 22.442939, "lng":114.278256, },
  {"lat": 22.443142, "lng":114.277937, },
  {"lat": 22.443317, "lng":114.277740, },
  {"lat": 22.443633, "lng":114.277695, },
  {"lat": 22.444040, "lng":114.277361, },
  {"lat": 22.444236, "lng":114.277353, },
  {"lat": 22.444713, "lng":114.277520, },
  {"lat": 22.444783, "lng":114.277634, },
  {"lat": 22.444881, "lng":114.278081, },
  {"lat": 22.445197, "lng":114.278408, },
  {"lat": 22.445442, "lng":114.278726, },
  {"lat": 22.445603, "lng":114.278817, },
  {"lat": 22.445898, "lng":114.279303, },
  {"lat": 22.446129, "lng":114.279424, },
  {"lat": 22.446368, "lng":114.279455, },
  {"lat": 22.446473, "lng":114.279523, },
  {"lat": 22.446613, "lng":114.279819, },
  {"lat": 22.446844, "lng":114.279978, },
  {"lat": 22.447062, "lng":114.280411, },
  {"lat": 22.448217, "lng":114.281055, },
  {"lat": 22.449184, "lng":114.281234, },
  {"lat": 22.449467, "lng":114.281719, },
  {"lat": 22.450080, "lng":114.28197, },
  {"lat": 22.450316, "lng":114.282331, },
  {"lat": 22.450788, "lng":114.282689, },
  {"lat": 22.451071, "lng":114.283020, },
  {"lat": 22.451920, "lng":114.284883, },
  {"lat": 22.451896, "lng":114.285342, },
  {"lat": 22.452250, "lng":114.285674, },
  {"lat": 22.452721, "lng":114.285776, },
  {"lat": 22.453132, "lng":114.286049, },
  {"lat": 22.453509, "lng":114.286023, },
  {"lat": 22.453651, "lng":114.286916, },
  {"lat": 22.452778, "lng":114.288013, },
  {"lat": 22.452731, "lng":114.288447, },
  {"lat": 22.452519, "lng":114.288753, },
  {"lat": 22.451033, "lng":114.289009, },
  {"lat": 22.450915, "lng":114.289264, },
  {"lat": 22.450900, "lng":114.289233, },
  {"lat": 22.450135, "lng":114.289377, },
  {"lat": 22.450035, "lng":114.289584, },
  {"lat": 22.449702, "lng":114.289593, },
  {"lat": 22.449386, "lng":114.289836, },
  {"lat": 22.449370, "lng":114.289953, },
  {"lat": 22.449145, "lng":114.290340, },
  {"lat": 22.449153, "lng":114.290484, },
  {"lat": 22.449544, "lng":114.290529, },
  {"lat": 22.449594, "lng":114.290673, },
  {"lat": 22.449852, "lng":114.291654, },
  {"lat": 22.450392, "lng":114.292050, },
  {"lat": 22.450517, "lng":114.292427, },
  {"lat": 22.451291, "lng":114.292220, },
  {"lat": 22.451582, "lng":114.292185, },
  {"lat": 22.451790, "lng":114.291834, },
  {"lat": 22.452433, "lng":114.291569, },
  {"lat": 22.453417, "lng":114.291805, },
  {"lat": 22.453598, "lng":114.292162, },
  {"lat": 22.453872, "lng":114.292183, },
  {"lat": 22.455012, "lng":114.291731, },
  {"lat": 22.455579, "lng":114.292021, },
  {"lat": 22.456115, "lng":114.292041, },
  {"lat": 22.456376, "lng":114.292284, },
  {"lat": 22.456432, "lng":114.292533, },
  {"lat": 22.456526, "lng":114.292580, },
  {"lat": 22.456868, "lng":114.292540, },
  {"lat": 22.457491, "lng":114.292135, },
  {"lat": 22.457684, "lng":114.292108, },
  {"lat": 22.458033, "lng":114.291893, },
  {"lat": 22.458270, "lng":114.292223, },
  {"lat": 22.458245, "lng":114.292405, },
  {"lat": 22.458264, "lng":114.292601, },
  {"lat": 22.458195, "lng":114.292762, },
  {"lat": 22.458002, "lng":114.292897, },
  {"lat": 22.457965, "lng":114.293045, },
  {"lat": 22.458039, "lng":114.293268, },
  {"lat": 22.457952, "lng":114.293349, },
  {"lat": 22.457510, "lng":114.293497, },
  {"lat": 22.457423, "lng":114.293652, },
  {"lat": 22.457273, "lng":114.293713, },
  {"lat": 22.457217, "lng":114.293814, },
  {"lat": 22.456943, "lng":114.293922, },
  {"lat": 22.456875, "lng":114.294057, },
  {"lat": 22.456414, "lng":114.294340, },
  {"lat": 22.456158, "lng":114.294414, },
  {"lat": 22.456096, "lng":114.294495, },
  {"lat": 22.455722, "lng":114.294771, },
  {"lat": 22.455615, "lng":114.294761, },
  {"lat": 22.455450, "lng":114.294664, },
  {"lat": 22.455503, "lng":114.294845, },
  {"lat": 22.455591, "lng":114.294950, },
  {"lat": 22.455787, "lng":114.295062, },
  {"lat": 22.455838, "lng":114.295133, },
  {"lat": 22.455864, "lng":114.295450, },
  {"lat": 22.455661, "lng":114.296413, },
  {"lat": 22.455666, "lng":114.296663, },
  {"lat": 22.455745, "lng":114.296932, },
  {"lat": 22.456005, "lng":114.297244, },
  {"lat": 22.456061, "lng":114.297598, },
  {"lat": 22.456049, "lng":114.298154, },
  {"lat": 22.456522, "lng":114.299155, },
  {"lat": 22.456543, "lng":114.299536, },
  {"lat": 22.456284, "lng":114.299596, },
  {"lat": 22.456029, "lng":114.299751, },
  {"lat": 22.455907, "lng":114.299775, },
  {"lat": 22.455742, "lng":114.299869, },
  {"lat": 22.455577, "lng":114.299886, },
  {"lat": 22.455471, "lng":114.299863, },
  {"lat": 22.455309, "lng":114.299792, },
  {"lat": 22.455188, "lng":114.299654, },
  {"lat": 22.455116, "lng":114.299488, },
  {"lat": 22.454742, "lng":114.299338, },
  {"lat": 22.454292, "lng":114.299791, },
  {"lat": 22.454229, "lng":114.299899, },
  {"lat": 22.454223, "lng":114.300097, },
  {"lat": 22.454533, "lng":114.302008, },
  {"lat": 22.454967, "lng":114.302767, },
  {"lat": 22.455245, "lng":114.302852, },
  {"lat": 22.455324, "lng":114.303198, },
  {"lat": 22.455523, "lng":114.303402, },
  {"lat": 22.455811, "lng":114.303487, },
  {"lat": 22.456340, "lng":114.303549, },
  {"lat": 22.456340, "lng":114.303549, },
  {"lat": 22.456445, "lng":114.303447, },
  {"lat": 22.456528, "lng":114.303408, },
  {"lat": 22.456811, "lng":114.303447, },
  {"lat": 22.457037, "lng":114.303686, },
  {"lat": 22.457052, "lng":114.303782, },
  {"lat": 22.457152, "lng":114.303816, },
  {"lat": 22.457340, "lng":114.303748, },
  {"lat": 22.457416, "lng":114.303620, },
  {"lat": 22.457411, "lng":114.303399, },
  {"lat": 22.457736, "lng":114.303436, },
  {"lat": 22.457644, "lng":114.303779, },
  {"lat": 22.458000, "lng":114.303816, },
  {"lat": 22.458338, "lng":114.303960, },
  {"lat": 22.458564, "lng":114.304136, },
  {"lat": 22.458941, "lng":114.303889, },
  {"lat": 22.459020, "lng":114.303909, },
  {"lat": 22.459104, "lng":114.303971, },
  {"lat": 22.459527, "lng":114.303909, },
  {"lat": 22.459615, "lng":114.303708, },
  {"lat": 22.459808, "lng":114.303632, },
  {"lat": 22.460397, "lng":114.303096, },
  {"lat": 22.460972, "lng":114.302876, },
  {"lat": 22.461329, "lng":114.302929, },
  {"lat": 22.461484, "lng":114.303142, },
  {"lat": 22.461582, "lng":114.303157, },
  {"lat": 22.462101, "lng":114.302952, },
  {"lat": 22.462367, "lng":114.302649, },
  {"lat": 22.462528, "lng":114.302315, },
  {"lat": 22.462718, "lng":114.302201, },
  {"lat": 22.462942, "lng":114.302239, },
  {"lat": 22.462977, "lng":114.302338, },
  {"lat": 22.462851, "lng":114.302474, },
  {"lat": 22.462675, "lng":114.302542, },
  {"lat": 22.462640, "lng":114.302724, },
  {"lat": 22.462514, "lng":114.302922, },
  {"lat": 22.462479, "lng":114.303278, },
  {"lat": 22.462409, "lng":114.303453, },
  {"lat": 22.462367, "lng":114.303756, },
  {"lat": 22.462416, "lng":114.303855, },
  {"lat": 22.462577, "lng":114.303991, },
  {"lat": 22.462640, "lng":114.304158, },
  {"lat": 22.462809, "lng":114.304219, },
  {"lat": 22.462949, "lng":114.304431, },
  {"lat": 22.463243, "lng":114.304651, },
  {"lat": 22.463313, "lng":114.304758, },
  {"lat": 22.463482, "lng":114.304811, },
  {"lat": 22.463580, "lng":114.304796, },
  {"lat": 22.463678, "lng":114.304849, },
  {"lat": 22.463881, "lng":114.304849, },
  {"lat": 22.464351, "lng":114.304560, },
  {"lat": 22.464603, "lng":114.304507, },
  {"lat": 22.464919, "lng":114.304583, },
  {"lat": 22.465164, "lng":114.304735, },
  {"lat": 22.465199, "lng":114.305038, },
  {"lat": 22.465382, "lng":114.305160, },
  {"lat": 22.465669, "lng":114.305190, },
  {"lat": 22.466132, "lng":114.305023, },
  {"lat": 22.466440, "lng":114.305175, },
  {"lat": 22.466742, "lng":114.305182, },
  {"lat": 22.467029, "lng":114.305243, },
  {"lat": 22.467282, "lng":114.305228, },
  {"lat": 22.467478, "lng":114.305334, },
  {"lat": 22.467709, "lng":114.305425, },
  {"lat": 22.468004, "lng":114.305835, },
  {"lat": 22.468305, "lng":114.306411, },
  {"lat": 22.468585, "lng":114.306518, },
  {"lat": 22.468719, "lng":114.306745, },
  {"lat": 22.469153, "lng":114.307322, },
  {"lat": 22.469223, "lng":114.307595, },
  {"lat": 22.469392, "lng":114.307716, },
  {"lat": 22.470170, "lng":114.307982, },
  {"lat": 22.470184, "lng":114.308058, },
  {"lat": 22.470619, "lng":114.308270, },
  {"lat": 22.470780, "lng":114.308361, },
  {"lat": 22.470885, "lng":114.308369, },
  {"lat": 22.471172, "lng":114.308960, },
  {"lat": 22.471362, "lng":114.309218, },
  {"lat": 22.471390, "lng":114.309370, },
  {"lat": 22.471593, "lng":114.309985, },
  {"lat": 22.471509, "lng":114.310440, },
  {"lat": 22.471404, "lng":114.311138, },
  {"lat": 22.471614, "lng":114.311661, },
  {"lat": 22.471663, "lng":114.312109, },
  {"lat": 22.471852, "lng":114.312223, },
  {"lat": 22.471901, "lng":114.312511, },
  {"lat": 22.472490, "lng":114.313141, },
  {"lat": 22.473535, "lng":114.313884, },
  {"lat": 22.474159, "lng":114.314104, },
  {"lat": 22.475021, "lng":114.314241, },
  {"lat": 22.475315, "lng":114.314643, },
  {"lat": 22.475722, "lng":114.314810, },
  {"lat": 22.476437, "lng":114.315720, },
  {"lat": 22.476753, "lng":114.315925, },
  {"lat": 22.476989, "lng":114.316112, },
  {"lat": 22.477289, "lng":114.317375, },
  {"lat": 22.476622, "lng":114.320082, },
  {"lat": 22.477856, "lng":114.320551, },
  {"lat": 22.478956, "lng":114.320515, },
  {"lat": 22.479790, "lng":114.320840, },
  {"lat": 22.479791, "lng":114.320989, },
  {"lat": 22.480309, "lng":114.321053, },
  {"lat": 22.480488, "lng":114.321405, },
  {"lat": 22.480370, "lng":114.321729, },
  {"lat": 22.479993, "lng":114.322061, },
  {"lat": 22.479381, "lng":114.322127, },
  {"lat": 22.479240, "lng":114.322216, },
  {"lat": 22.478792, "lng":114.322624, },
  {"lat": 22.477625, "lng":114.323288, },
  {"lat": 22.477153, "lng":114.323339, },
  {"lat": 22.476722, "lng":114.323508, },
  {"lat": 22.475605, "lng":114.323165, },
  {"lat": 22.475338, "lng":114.322912, },
  {"lat": 22.474888, "lng":114.322714, },
  {"lat": 22.474237, "lng":114.322768, },
  {"lat": 22.474079, "lng":114.322849, },
  {"lat": 22.473996, "lng":114.322795, },
  {"lat": 22.473554, "lng":114.322867, },
  {"lat": 22.473229, "lng":114.323129, },
  {"lat": 22.473204, "lng":114.323210, },
  {"lat": 22.473259, "lng":114.323253, },
  {"lat": 22.473363, "lng":114.323249, },
  {"lat": 22.473349, "lng":114.323389, },
  {"lat": 22.473141, "lng":114.323507, },
  {"lat": 22.473121, "lng":114.323546, },
  {"lat": 22.473003, "lng":114.323606, },
  {"lat": 22.472850, "lng":114.323606, },
  {"lat": 22.472723, "lng":114.323549, },
  {"lat": 22.472611, "lng":114.323558, },
  {"lat": 22.472298, "lng":114.323724, },
  {"lat": 22.471836, "lng":114.324260, },
  {"lat": 22.471762, "lng":114.324298, },
  {"lat": 22.471715, "lng":114.324369, },
  {"lat": 22.471467, "lng":114.324755, },
  {"lat": 22.471435, "lng":114.324898, },
  {"lat": 22.471376, "lng":114.324965, },
  {"lat": 22.471349, "lng":114.325089, },
  {"lat": 22.471201, "lng":114.325123, },
  {"lat": 22.471247, "lng":114.325413, },
  {"lat": 22.471305, "lng":114.325432, },
  {"lat": 22.471249, "lng":114.325414, },
  {"lat": 22.471309, "lng":114.325430, },
  {"lat": 22.471485, "lng":114.325985, },
  {"lat": 22.471692, "lng":114.326016, },
  {"lat": 22.471505, "lng":114.326219, },
  {"lat": 22.471577, "lng":114.326656, },
  {"lat": 22.471645, "lng":114.326851, },
  {"lat": 22.471456, "lng":114.327129, },
  {"lat": 22.471353, "lng":114.327520, },
  {"lat": 22.471164, "lng":114.327789, },
  {"lat": 22.471267, "lng":114.328236, },
  {"lat": 22.471492, "lng":114.328379, },
  {"lat": 22.472002, "lng":114.328324, },
  {"lat": 22.472086, "lng":114.328218, },
  {"lat": 22.472311, "lng":114.328186, },
  {"lat": 22.472344, "lng":114.328223, },
  {"lat": 22.472229, "lng":114.328410, },
  {"lat": 22.472268, "lng":114.328440, },
  {"lat": 22.472432, "lng":114.328443, },
  {"lat": 22.472684, "lng":114.328719, },
  {"lat": 22.472746, "lng":114.328998, },
  {"lat": 22.472945, "lng":114.329136, },
  {"lat": 22.473598, "lng":114.329352, },
  {"lat": 22.473904, "lng":114.329628, },
  {"lat": 22.474047, "lng":114.330141, },
  {"lat": 22.473853, "lng":114.330649, },
  {"lat": 22.473542, "lng":114.330969, },
  {"lat": 22.473118, "lng":114.331246, },
  {"lat": 22.472899, "lng":114.331246, },
  {"lat": 22.472113, "lng":114.331135, },
  {"lat": 22.471812, "lng":114.331152, },
  {"lat": 22.471769, "lng":114.331137, },
  {"lat": 22.471697, "lng":114.330991, },
  {"lat": 22.471630, "lng":114.330918, },
  {"lat": 22.471226, "lng":114.330851, },
  {"lat": 22.471100, "lng":114.330898, },
  {"lat": 22.470671, "lng":114.331222, },
  {"lat": 22.470682, "lng":114.331496, },
  {"lat": 22.470415, "lng":114.331624, },
  {"lat": 22.470180, "lng":114.331781, },
  {"lat": 22.470032, "lng":114.331703, },
  {"lat": 22.470023, "lng":114.331681, },
  {"lat": 22.470027, "lng":114.331599, },
  {"lat": 22.470039, "lng":114.331562, },
  {"lat": 22.470090, "lng":114.331511, },
  {"lat": 22.470268, "lng":114.331552, },
  {"lat": 22.470321, "lng":114.331507, },
  {"lat": 22.470357, "lng":114.331437, },
  {"lat": 22.470386, "lng":114.331234, },
  {"lat": 22.470238, "lng":114.331064, },
  {"lat": 22.470279, "lng":114.330908, },
  {"lat": 22.470359, "lng":114.330910, },
  {"lat": 22.470499, "lng":114.330990, },
  {"lat": 22.470638, "lng":114.330959, },
  {"lat": 22.470721, "lng":114.330779, },
  {"lat": 22.470967, "lng":114.330354, },
  {"lat": 22.470967, "lng":114.330354, },
  {"lat": 22.470999, "lng":114.330180, },
  {"lat": 22.470930, "lng":114.329985, },
  {"lat": 22.470930, "lng":114.329469, },
  {"lat": 22.470891, "lng":114.329385, },
  {"lat": 22.470745, "lng":114.329381, },
  {"lat": 22.470452, "lng":114.329110, },
  {"lat": 22.470149, "lng":114.328981, },
  {"lat": 22.469879, "lng":114.328923, },
  {"lat": 22.469819, "lng":114.328843, },
  {"lat": 22.469619, "lng":114.328864, },
  {"lat": 22.469372, "lng":114.328956, },
  {"lat": 22.469069, "lng":114.328926, },
  {"lat": 22.468825, "lng":114.329020, },
  {"lat": 22.468544, "lng":114.329008, },
  {"lat": 22.468437, "lng":114.328925, },
  {"lat": 22.468452, "lng":114.328753, },
  {"lat": 22.468488, "lng":114.328669, },
  {"lat": 22.468457, "lng":114.328571, },
  {"lat": 22.450768, "lng":114.338281, },
  {"lat": 22.448598, "lng":114.337802, },
  {"lat": 22.446312, "lng":114.341159, },
  {"lat": 22.442556, "lng":114.340629, },
  {"lat": 22.440433, "lng":114.337978, },
  {"lat": 22.441086, "lng":114.334091, },
  {"lat": 22.439453, "lng":114.333031, },
  {"lat": 22.436840, "lng":114.335151, },
  {"lat": 22.434717, "lng":114.336918, },
  {"lat": 22.433737, "lng":114.337271, },
  {"lat": 22.429817, "lng":114.335681, },
  {"lat": 22.428837, "lng":114.333208, },
  {"lat": 22.427531, "lng":114.335681, },
  {"lat": 22.428674, "lng":114.337802, },
  {"lat": 22.427857, "lng":114.340099, },
  {"lat": 22.427857, "lng":114.340099, },
  {"lat": 22.424591, "lng":114.353880, },
  {"lat": 22.424807, "lng":114.356220, },
  {"lat": 22.425778, "lng":114.355800, },
  {"lat": 22.426555, "lng":114.354749, },
  {"lat": 22.427117, "lng":114.355586, },
  {"lat": 22.427617, "lng":114.355261, },
  {"lat": 22.427817, "lng":114.353674, },
  {"lat": 22.428545, "lng":114.353818, },
  {"lat": 22.428529, "lng":114.354305, },
  {"lat": 22.429765, "lng":114.354760, },
  {"lat": 22.430618, "lng":114.354018, },
  {"lat": 22.433286, "lng":114.354131, },
  {"lat": 22.433710, "lng":114.355228, },
  {"lat": 22.434182, "lng":114.355305, },
  {"lat": 22.434701, "lng":114.354947, },
  {"lat": 22.435125, "lng":114.354309, },
  {"lat": 22.437130, "lng":114.354463, },
  {"lat": 22.439654, "lng":114.356070, },
  {"lat": 22.440880, "lng":114.354947, },
  {"lat": 22.441847, "lng":114.355356, },
  {"lat": 22.442578, "lng":114.355049, },
  {"lat": 22.445998, "lng":114.356734, },
  {"lat": 22.445809, "lng":114.355254, },
  {"lat": 22.445125, "lng":114.354794, },
  {"lat": 22.445008, "lng":114.353416, },
  {"lat": 22.447662, "lng":114.354118, },
  {"lat": 22.448911, "lng":114.352409, },
  {"lat": 22.450538, "lng":114.354715, },
  {"lat": 22.449247, "lng":114.355383, },
  {"lat": 22.449864, "lng":114.356475, },
  {"lat": 22.449696, "lng":114.357203, },
  {"lat": 22.451547, "lng":114.357628, },
  {"lat": 22.451603, "lng":114.358235, },
  {"lat": 22.452276, "lng":114.359085, },
  {"lat": 22.452276, "lng":114.359085, },
  {"lat": 22.451632, "lng":114.361877, },
  {"lat": 22.451434, "lng":114.362054, },
  {"lat": 22.451444, "lng":114.362258, },
  {"lat": 22.451647, "lng":114.362033, },
  {"lat": 22.451821, "lng":114.362059, },
  {"lat": 22.452361, "lng":114.362371, },
  {"lat": 22.452572, "lng":114.362346, },
  {"lat": 22.452847, "lng":114.362102, },
  {"lat": 22.453187, "lng":114.361898, },
  {"lat": 22.453521, "lng":114.361823, },
  {"lat": 22.453653, "lng":114.361906, },
  {"lat": 22.453930, "lng":114.361898, },
  {"lat": 22.454261, "lng":114.361536, },
  {"lat": 22.455431, "lng":114.361043, },
  {"lat": 22.456790, "lng":114.360828, },
  {"lat": 22.458465, "lng":114.361204, },
  {"lat": 22.458614, "lng":114.361128, },
  {"lat": 22.459665, "lng":114.361680, },
  {"lat": 22.459827, "lng":114.361692, },
  {"lat": 22.460061, "lng":114.361547, },
  {"lat": 22.460216, "lng":114.361335, },
  {"lat": 22.460328, "lng":114.360910, },
  {"lat": 22.460524, "lng":114.360880, },
  {"lat": 22.460699, "lng":114.361084, },
  {"lat": 22.460628, "lng":114.361454, },
  {"lat": 22.460282, "lng":114.361915, },
  {"lat": 22.458468, "lng":114.366653, },
  {"lat": 22.457865, "lng":114.367245, },
  {"lat": 22.456028, "lng":114.368171, },
  {"lat": 22.455131, "lng":114.369582, },
  {"lat": 22.454514, "lng":114.369688, },
  {"lat": 22.453953, "lng":114.370204, },
  {"lat": 22.449690, "lng":114.369571, },
  {"lat": 22.448184, "lng":114.368199, },
  {"lat": 22.447052, "lng":114.366362, },
  {"lat": 22.436957, "lng":114.370241, },
  {"lat": 22.438467, "lng":114.376671, },
  {"lat": 22.436108, "lng":114.381673},
  {"lat": 22.440519, "lng":114.391779, },
  {"lat": 22.440188, "lng":114.392494, },
  {"lat": 22.437287, "lng":114.392136, },
  {"lat": 22.437004, "lng":114.392825, },
  {"lat": 22.438820, "lng":114.394458, },
  {"lat": 22.435518, "lng":114.396908, },
  {"lat": 22.436322, "lng":114.401046, },
  {"lat": 22.435827, "lng":114.401313, },
  {"lat": 22.434141, "lng":114.400003, },
  {"lat": 22.433744, "lng":114.399403, },
  {"lat": 22.434373, "lng":114.399214, },
  {"lat": 22.433948, "lng":114.398729, },
  {"lat": 22.433665, "lng":114.398907, },
  {"lat": 22.433359, "lng":114.398346, },
  {"lat": 22.433052, "lng":114.398780, },
  {"lat": 22.431181, "lng":114.398603, },
  {"lat": 22.430679, "lng":114.397994, },
  {"lat": 22.430686, "lng":114.397768, },
  {"lat": 22.430910, "lng":114.397791, },
  {"lat": 22.430525, "lng":114.397139, },
  {"lat": 22.430735, "lng":114.396782, },
  {"lat": 22.430279, "lng":114.396661, },
  {"lat": 22.429220, "lng":114.394992, },
  {"lat": 22.428954, "lng":114.396023, },
  {"lat": 22.427916, "lng":114.396949, },
  {"lat": 22.426738, "lng":114.396843, },
  {"lat": 22.426976, "lng":114.397419, },
  {"lat": 22.426692, "lng":114.397955, },
  {"lat": 22.424570, "lng":114.397612, },
  {"lat": 22.424848, "lng":114.397097, },
  {"lat": 22.424471, "lng":114.396968, },
  {"lat": 22.423975, "lng":114.397591, },
  {"lat": 22.423028, "lng":114.396787, },
  {"lat": 22.422661, "lng":114.397690, },
  {"lat": 22.421593, "lng":114.399025, },
  {"lat": 22.420426, "lng":114.401551, },
  {"lat": 22.419558, "lng":114.403391, },
  {"lat": 22.418791, "lng":114.403572, },
  {"lat": 22.418591, "lng":114.403103, },
  {"lat": 22.417357, "lng":114.402778, },
  {"lat": 22.417223, "lng":114.404005, },
  {"lat": 22.417490, "lng":114.404297, },
  {"lat": 22.416656, "lng":114.405091, },
  {"lat": 22.415703, "lng":114.404414, },
  {"lat": 22.415882, "lng":114.404007, },
  {"lat": 22.415614, "lng":114.403506, },
  {"lat": 22.410328, "lng":114.402069, },
  {"lat": 22.420095, "lng":114.386707, },
  {"lat": 22.409385, "lng":114.379103, },
  {"lat": 22.409102, "lng":114.378082, },
  {"lat": 22.410328, "lng":114.377061, },
  {"lat": 22.401694, "lng":114.375785, },
  {"lat": 22.399665, "lng":114.370631, },
  {"lat": 22.398438, "lng":114.377265, },
  {"lat": 22.389663, "lng":114.384934, },
  {"lat": 22.390855, "lng":114.386893, },
  {"lat": 22.387669, "lng":114.391107, },
  {"lat": 22.376849, "lng":114.392148, },
  {"lat": 22.376780, "lng":114.386694, },
  {"lat": 22.367564, "lng":114.392322, },
  {"lat": 22.364607, "lng":114.389620, },
  {"lat": 22.365661, "lng":114.386868, },
  {"lat": 22.377238, "lng":114.382108, },
  {"lat": 22.374189, "lng":114.381662, },
  {"lat": 22.373685, "lng":114.379059, },
  {"lat": 22.375680, "lng":114.376828, },
  {"lat": 22.373754, "lng":114.375737, },
  {"lat": 22.369421, "lng":114.376133, },
  {"lat": 22.366532, "lng":114.381191, },
  {"lat": 22.363483, "lng":114.380794, },
  {"lat": 22.265207, "lng":114.372534, },
  {"lat": 22.235355, "lng":114.285570, },
  {"lat": 22.276014, "lng":114.263140, },
  {"lat": 22.299521, "lng":114.261424, },
  {"lat": 22.284501, "lng":114.242151, },
  {"lat": 22.307639, "lng":114.242151, },
  {"lat": 22.327894, "lng":114.229633, },
  {"lat": 22.334303, "lng":114.228189, },
  {"lat": 22.332968, "lng":114.220972, },
  {"lat": 22.340555, "lng":114.213304, },
  {"lat": 22.357657, "lng":114.212323, },
  {"lat": 22.353324, "lng":114.228256, },
  {"lat": 22.372581, "lng":114.247877, },
  

]:
  isHY202318?[
    { "lat":22.314256, "lng":114.257879},
    { "lat":22.314204, "lng":114.257966},
    { "lat":22.314414, "lng":114.258204},
    { "lat":22.314472, "lng":114.258118},
  ]:null

export const defaultMapProps = 
is1858EM23T?
{
  center: {
    lat: 22.3001089,
    lng: 114.1759621
  },
  zoom: 14
}:
is2WSD23?{
  center: {
    lat: 22.314486,
    lng: 114.135579
  },
  zoom: 12
}:
isTcj911?{
  center: {
    lat: 22.285912,
    lng: 114.143895
  },
  zoom: 18
}:
  isTCM941?{
    center: {
      lat: 22.364474,
      lng: 114.105322
    },
    zoom: 13
  }:
  isFOOKLEEUNION?{
    center: {
      lat: 22.370690,
      lng: 114.221927
    },
    zoom: 12
  }:
  isHY202318?{
    center: {
      lat: 22.249697,
      lng: 114.173957
    },
    zoom: 18
  }:
  isSSM508?
  {
    center: {
      lat: 22.276735,
      lng: 114.179534
    },
    zoom: 19
  }
  :isFLN?
  {
    center: {
      lat: 22.48395,
      lng: 114.15340
    },
    zoom: 17
  }:{
    center: {
      lat: 22.3001089,
      lng: 114.1759621
    },
    zoom: 17
  };