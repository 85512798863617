import { useNavigate } from 'react-router-dom';
import SmallHeader from '../../svgs/tracking/SmallHeader'
import LockAlertList from '../LockPage/LockAlertList';
import CopyLink from '../CopyLink';
import DetailArrowSvg from '../../svgs/summary/DetailArrowSvg'
import { isPrintWhiteMode } from '../../config';
import { connect } from 'react-redux';
import { forwardRef } from 'react';

const Component= forwardRef((props,ref)=>{
    const isWhiteMode= props.isReport&&isPrintWhiteMode;
    const navigate = useNavigate()
    return(
        <div className="w-full flex flex-col" style={{color:isWhiteMode?'#000':"#fff"}}>
                <div className="flex flex-row w-full justify-between items-center pb-[8px]" style={{}}>
                    <div className="text-[16px] font-[300] flex flex-row items-center gap-2"><SmallHeader color={isWhiteMode?'#000':'#fff'}/>Smart-Lock Alerts<CopyLink color={isWhiteMode?'#000':'#fff'} /></div>
                    {!props.isReport &&<div className='text-[12px] flex flex-row justify-center gap-2 cursor-pointer' onClick={() => {navigate('/smartlock')}}>Details<DetailArrowSvg /></div>}
                </div>

                <LockAlertList ref={ref} isReport={props.isReport}/>
        </div>
    )
})


export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  },null,null,{forwardRef:true})(Component)