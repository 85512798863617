
import './Tracking.css'
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, generatePath, useSearchParams, useLocation } from "react-router-dom";
import {createPortal} from 'react-dom'
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import { createTempData } from "../components/charts/func/tempData";
import EventTypeComp from '../components/tracking/EventTypeComp'
import DataPicker from '../components/DatePickerRange'
import PopUpBase from '../components/PopUpBase'
import CreatePopUp from '../components/tracking/CreatePopUp';
import EditPopUp from '../components/tracking/EditPopUp';
import NoPermission from './NoPermission'
import DropDownMenu from '../components/DropdownMenu'
import CopyLink from '../components/CopyLink'
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import ChartChainLink from '../svgs/ChartChainLink'
import SmallSearchSvg from '../svgs/watchPage/SmallSearchSvg'
import SmallPointDownSvg from '../svgs/watchPage/SmallPointDownSvg'
import SmallCalenderSvg from '../svgs/watchPage/SmallCalenderSvg'
import SmallPointLeftSvg from '../svgs/watchPage/SmallPointLeftSvg'
import ExspandSvg from '../svgs/tracking/ExspandSvg'
import PictureSvg from '../svgs/tracking/PictureSvg'
import PlaySvg from '../svgs/tracking/PlaySvg'
import ThreeLineSvg from '../svgs/tracking/ThreeLineSvg'
import TickSvg from '../svgs/tracking/tickSvg'
import DotDotDotSvg from '../svgs/tracking/DotDotDotSvg'
import GreyPointDown from '../svgs/tracking/GreyPointDown'
import { Ticks } from 'chart.js';
import PageSelector from '../components/PageSelector'
import { handleFilterDate } from '../actions/actionsDateTime';
//API
import {fetchTrackingCategory, fetchTrackingHistory} from '../services/httpApiTracking'
//Action
import {validateItemWithDate, getDateOnly, yearMonthDay} from '../actions/actionsDateTime'
import { create } from 'qrcode';
import { enableDownloadTrackingAsExcel, showTrackingExaminationDate, showTrackingLocation, showTrackingStatus } from '../config';
//
import {utils,writeFile} from 'xlsx';

const CHART_TITLE = [
    // {title: '', field: null, flex: 1},
    {title: 'Item', field: null, flex: 1,mobileHidden:true},
    {title: 'Validity', field: '', flex: 1},
    {title: 'Equipment ID', field: 'equipmentId', flex: 2},
    ...(showTrackingStatus?[{title: 'Status', field: 'status', flex: 2,mobileHidden:true}]:[]),
    ...(showTrackingLocation?[{title: 'Location/Site', field: 'location', flex: 2,mobileHidden:true}]:[]),
    {title: 'Category', field: 'category', flex: 2},
    {title: 'Certificate Date', field: '', flex: 3,mobileHidden:true},
    //{title: 'Status', field: '', flex: 2},
    {title: 'Last Maintenance', field: 'maintenanceDate', flex: 2,mobileHidden:true},
    ...(showTrackingExaminationDate?[{title: 'Examination Date', field: 'examination', flex: 2,mobileHidden:true}]:[]),
    {title: 'Remarks', field: 'remarks', flex: 2,mobileHidden:true},
    {title: 'Record', field: '', flex: 1,mobileHidden:true},
    {title: 'Action', field: '', flex: 1,mobileHidden:true},
]

const filterSelection = {
    None: [],
    Validity: ['All','Valid', 'Invalid'],
}


function Tracking(props){
    // const hasPerms = props.control.permissions
    const navigate = useNavigate();
    const fromRef = useRef(null)
    const toRef = useRef(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const page =  parseInt(searchParams.get('page'))||1;
    const filterType= searchParams.get('filterType');
    const isValid = searchParams.get('validity')
    const isCategory = searchParams.get('category')
    const filterSearch = searchParams.get('search')
    const filterSearchCategory = searchParams.get('search-category')
    const [trackingData, setTrackingData] = useState(undefined)
    const [limit, setLimit] = useState(10)
    const [total,setTotal]= useState(0)
    const totalPage = Math.ceil(total/limit)||1
    const [createPopup, setCreatePopUp] = useState(false)
    const [popType, setPopType] = useState(undefined)
    const filterDate = (searchParams.get('date') || 'All')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')
    useEffect(() => {
        if(!createPopup){
            const timer = setTimeout(() => fetchData(), 500)
            return () => clearTimeout(timer)
        }
    },[searchParams,props.control,createPopup])
    
    async function fetchData(l=limit,p=page){
        if(!props.control?.permissions?.TRACKING_VIEW || !props.control?.projectId) return
        //const filter = getValidityFilter1(isValid, isCategory, new Date().toISOString(),filterSearch,filterDate)
        const filter = {}
        filter["projectId"] = props.control.projectId
        if(filterSearch) filter["equipmentId"] =  {"$regex": filterSearch, "$options":'i'}
        if(filterDate!=='All') {
            const obj = handleFilterDate(filterDate)
            obj["$gte"] = new Date(obj["$gte"])
            obj["$lte"] = new Date(obj["$lte"])
            filter['certificateEndDate'] = obj
        }
        if(filterFrom && filterTo) {
            const obj = handleFilterDate('', filterFrom, filterTo)
            obj["$gte"] = new Date(obj["$gte"])
            obj["$lte"] = new Date(obj["$lte"])
            filter['certificateEndDate'] = obj
        }
        const currentTime = new Date().toISOString();
        if(isValid === 'Valid') {
            filter["certificateStartDate"] = {"$lt": `${currentTime}`}
            filter["certificateEndDate"] = {"$gte": `${currentTime}`}   
        }else if (isValid === 'Invalid'){
            filter["$or"] = [
                    { "certificateStartDate": {"$lt": `${currentTime}`},
                    "certificateEndDate": {"$lt": `${currentTime}`}
                },
                    { "certificateStartDate": {"$gte": `${currentTime}`},
                    "certificateEndDate": {"$gte": `${currentTime}`}
                }
            ]
        }
        if(filterSearchCategory) filter["category"] =  {"$regex": filterSearchCategory, "$options":'i'}
        else if(isCategory) filter['category'] = {"$eq": isCategory}
        
        const data = await fetchTrackingHistory(filter,{limit:l ,skip:l*(p-1),sort:{certificateEndDate:1}})
        if(data?.errno) return console.log('error fetching tracking record')
        if(data.result) {
            setTrackingData(data.result)
            setTotal(data?.count)
        }
        if(page > Math.ceil(data?.count/limit)) handleSelectPage(1)()
    }
    function handleValidityChange(val){
        searchParams.delete('page')
        if(val === 'All'){
            searchParams.delete('validity')
            return setSearchParams(searchParams)
        }
        searchParams.set('validity', val)
        setSearchParams(searchParams)
    }
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(['from','to'].includes(key)) searchParams.delete('date')
        if(key === 'date'){
            searchParams.delete('from')
            searchParams.delete('to') 
        }
        if(key === 'clearDate') {
            searchParams.delete('from')
            searchParams.delete('to')
            searchParams.delete('date')
            fromRef.current.value = ""
            toRef.current.value = ""
        }
        if(key !== 'page'){
            searchParams.delete('page')
            searchParams.append('page', 1)
        }
        if(val !== '' && key !== 'clearDate') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function handleDateChange(val){
        console.log(val !== 'Select Date')
        val !== 'Select Date' ? handleParamChange('date', val) : setPopType('date')

    }
    function handleCategoryChange(val){
        searchParams.delete('page')
        if(val === 'All'){
            searchParams.delete('category')
            return setSearchParams(searchParams)
        }
        searchParams.set('category', val)
        setSearchParams(searchParams)
    }
    const handleSelectPage=(p)=>async ()=>{
        if(p > totalPage || p < 1) return;
        searchParams.delete('page')
        searchParams.append('page',p)
        setSearchParams(searchParams)
    }
    function handleFilterSet(val){
        const oldVal = searchParams.get(filterType)
        searchParams.delete(filterType)
        if(oldVal !== val)searchParams.append(filterType,val)
        setSearchParams(searchParams)
    }
    //setting func


    //categories
    const [categoryList,setCategoryList]=useState([])
    const fetchCatList=async ()=>{
        let result = await fetchTrackingCategory(props.token.token,'category',{projectId:props.control?.projectId})
        if(result?.result)setCategoryList(result?.result)
    }
    useEffect(()=>{
        if(props.control?.projectId)fetchCatList()
    },[props.control?.projectId])


    const handleDownloadExcel=async ()=>{
        const filter = {}
        filter["projectId"] = props.control.projectId
        if(filterSearch) filter["equipmentId"] =  {"$regex": filterSearch, "$options":'i'}
        if(filterDate!=='All') {
            const obj = handleFilterDate(filterDate)
            obj["$gte"] = new Date(obj["$gte"])
            obj["$lte"] = new Date(obj["$lte"])
            filter['certificateEndDate'] = obj
        }
        if(filterFrom && filterTo) {
            const obj = handleFilterDate('', filterFrom, filterTo)
            obj["$gte"] = new Date(obj["$gte"])
            obj["$lte"] = new Date(obj["$lte"])
            filter['certificateEndDate'] = obj
        }
        const currentTime = new Date().toISOString();
        if(isValid === 'Valid') {
            filter["certificateStartDate"] = {"$lt": `${currentTime}`}
            filter["certificateEndDate"] = {"$gte": `${currentTime}`}   
        }else if (isValid === 'Invalid'){
            filter["$or"] = [
                    { "certificateStartDate": {"$lt": `${currentTime}`},
                    "certificateEndDate": {"$lt": `${currentTime}`}
                },
                    { "certificateStartDate": {"$gte": `${currentTime}`},
                    "certificateEndDate": {"$gte": `${currentTime}`}
                }
            ]
        }
        if(filterSearchCategory) filter["category"] =  {"$regex": filterSearchCategory, "$options":'i'}
        else if(isCategory) filter['category'] = {"$eq": isCategory}
        
        const data = await fetchTrackingHistory(filter,{limit:999999,sort:{certificateEndDate:1}})
        if(data?.errno) return alert('error fetching tracking record')
        if(data.result) {
           let list = data.result
           let excelData=[['Item','Validity','Equipment ID','Status','Location/Site','Category','Certificate Date','Last Maintenance','Remarks','Record']]
           for(let index in list){
            excelData.push([
                Number(index)+1,
                validateItemWithDate(list[index].certificateStartDate, list[index].certificateEndDate)?'Valid':'Invalid',
                list[index].equipmentId||'',
                list[index].status||'',
                list[index].location||'',
                list[index].category||'',
                list[index]?.certificateStartDate&&list[index]?.certificateEndDate? `${getDateOnly(list[index]?.certificateStartDate)} To ${getDateOnly(list[index]?.certificateEndDate)}`:'',
                list[index].maintenanceDate? getDateOnly(list[index].maintenanceDate):'',
                list[index].remarks||'',
                `${list[index].documents?.length||'0'} File(s)`,
            ])
           }

           const ws = utils.aoa_to_sheet(excelData);
           const wb = utils.book_new();
           utils.book_append_sheet(wb, ws, `Tracking Data`);
           writeFile(wb, `Tracking.xlsx`);
        }
    }


    if(props.control?.permissions?.TRACKING_VIEW === false) return(<NoPermission />)
    return(
        <div className='w-full h-full bg-[#0C0D14] flex flex flex-col relative overflow-auto'>
            {createPopup && createPortal(<PopUpBase closePopUp={()=>{}}> <CreatePopUp setCreatePopUp={setCreatePopUp}/> </PopUpBase >, props.portalRef.current)}
            <div style={{...LEFTRIGHT_PADDING}} className='pb-64'>
                <div className="flex flex-row mt-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">Tracking</div>
                </div>
                <div className="mt-[24px] pt-[13px] flex flex-row items-center justify-between select-none flex-wrap gap-[2px]">
                        <div className="flex flex-row gap-2 text-white items-center flex-1 flex-wrap">
                            {/* <DropDownMenu value={((filterType&&filterType!=='None') ? filterType:'Select filter')} options={filterTypeArr} select={handleFilterType}/> */}
                            <DropDownMenu value={isValid || 'Validity'} options={filterSelection.Validity} select={handleValidityChange}/>
                            <DropDownMenu value={isCategory || 'Category'}options={['All',...categoryList]} select={handleCategoryChange}/>
                            {(filterType&&filterType!=='None') && <DropDownMenu value={(searchParams.get(filterType) || 'Select filter Type')} options={filterSelection[filterType]} select={handleFilterSet}/>}
                        </div>
                        <div className="flex flex-row gap-2 text-white flex-wrap">
                            {/* <div className="rounded-full flex flex-row items-center max-w-[225px] px-[16px] py-[2px]" style={{borderColor:'#FFFFFF80', borderWidth:1}}>
                                <SmallCalenderSvg className='mr-[12px]' />
                                <input type='date' value={filterDate} onChange={(e) => handleParamChange('date', e.target.value)} className="bg-transparent h-[21px] text-white min-w-0 outline-none cursor-pointer" placeholder={ new Date(Date.now()-7*1000*60*60*24).toLocaleDateString()  + ' - Now'} style={{boxSizing:'border-box', colorScheme:'dark'}}></input>
                            </div>  */}
                            <DropDownMenu options={['All','Today','7 Days','30 Days','Select Date']} value={(filterFrom && filterTo ? `${filterFrom} / ${filterTo}` : filterDate)} select = {handleDateChange}/>
                            <div className="rounded-full flex flex-row items-center justify-between max-w-[225px] px-[16px] py-[2px]" style={{borderColor:'#FFFFFF80', borderWidth:1}}>
                                <SmallSearchSvg className='mr-[12px]' />
                                <input onChange={(e) => {handleParamChange('search',e.target.value)}} className="bg-transparent h-[21px] text-white min-w-0 outline-none" placeholder="Equipment ID"></input>
                            </div>
                            <div className="rounded-full flex flex-row items-center justify-between max-w-[225px] px-[16px] py-[2px]" style={{borderColor:'#FFFFFF80', borderWidth:1}}>
                                <SmallSearchSvg className='mr-[12px]' />
                                <input onChange={(e) => {handleParamChange('search-category',e.target.value)}} className="bg-transparent h-[21px] text-white min-w-0 outline-none" placeholder="Category"></input>
                            </div>
                        </div>
                </div>
                <div className="rounded-xl mt-[16px]">
                    <div className="flex flex-row items-center justify-between py-[24px] px-[16px] flex-wrap gap-[4px]" style={{borderBottomWidth: .5, borderBottomColor:'#FFFFFF4D'}}>
                        <div className="flex flex-row items-center">
                            <div className="text-white text-[18px] font-bold flex items-center select-none mr-2">Digital Tracking</div>
                            <CopyLink />
                        </div>
                        <div className="flex flex-row items-center overflow-hidden gap-2 select-none">
                            {/* <div className="text-white flex flex-row gap-2 mr-[21px]">
                                Real Time
                                <div className="flex flex-row" style={{width:35, height: 22, borderRadius:99, backgroundColor:'grey', padding:2, justifyContent:'end'}}>
                                    <div style={{width:18, height: 18, borderRadius:99, backgroundColor:'#00202A'}}/>
                                </div>
                            </div> */}
                            {enableDownloadTrackingAsExcel && <div className="button_blue" onClick={handleDownloadExcel}>
                                Download Excel
                            </div>}
                            {props.control?.permissions?.TRACKING_CREATE && <div className="button_blue" onClick={() => {setCreatePopUp(!createPopup)}}>
                                + Add Device / Item
                            </div>}
                        </div>
                    </div>
                    <div className="py-[32px] px-[0px]">
                        <div className="flex flex-row text-[14px]">
                            {CHART_TITLE.map((obj, i) => (
                                <div key={'title'+ obj.title} className={`table_header ${obj.mobileHidden?'d-flex-m-hidden':""}`} style={{flex: obj.flex || 1, flexShrink: obj.srink ? 2 : 0}}>
                                    {['Company', 'Alert ID', 'Camera Name', 'Engine', 'Approved By', 'Comment'].includes(obj.title) && <ThreeLineSvg className=''/>}
                                    {obj.title}
                                    {['Battery','Event','Date','Heart Rate','Body Temperature','Standstill'].includes(obj.title) && <SmallPointLeftSvg style={{transform: 'rotate(-90deg)'}} />}
                                </div>
                            ))}
                        </div>
                        {trackingData?.map((items, idx) =>
                            <div key={'TrackP2' + idx} className={`table_row cursor-pointer select-none ${idx%2===1?'tracking_item_1':'tracking_item_2'}`} onClick={() => navigate('/tracking/detail/' + encodeURIComponent(items._id) , {state:{id: items._id}})} style={{overflowWrap: 'break-word'}}>
                                {CHART_TITLE.map((h, i) => 
                                <div key={'TrackP2' + h.title + i} className={`table_item ${h.mobileHidden?'d-flex-m-hidden':""}`}  style={{flex: h.flex}}>{
                                    h.title === 'Item' ? 
                                        (idx + 1) + ((page - 1) * limit):
                                    h.title === 'Certificate Date' ? 
                                        getDateOnly(items.certificateStartDate) + ' To ' + getDateOnly(items.certificateEndDate):
                                    h.title === 'Last Maintenance' ? 
                                        getDateOnly(items.maintenanceDate):
                                    h.title === 'Examination Date' ? 
                                        getDateOnly(items.examination):
                                    h.title === 'Validity' ? 
                                        <div className='flex flex-row items-center justify-center' style={{borderRadius:99, padding:6, borderColor:validateItemWithDate(items.certificateStartDate, items.certificateEndDate) ? '#29FFD9' : '#FFC329', borderWidth: 1, width: 33, height:33}}>
                                            <div className='w-[17px] h-[17px] rounded-full' style={{backgroundColor: validateItemWithDate(items.certificateStartDate, items.certificateEndDate) ? '#29FFD9' : '#FFC329'}}/>
                                        </div> :
                                    h.title === 'Record' ? `${(items.documents?.length)} File(s)` :
                                    h.title === 'Remarks' ? `${items?.[h.field]?.slice(0,7)||''}${items?.[h.field]?.length>7?'...':''}` :
                                    // h.title === 'Status' ? <EventTypeComp type={'checked'} valid={validateItemWithDate(items.certificateStartDate, items.certificateEndDate)}/> :
                                    h.title === 'Action' ?  <div className='cursor-pointer hover:scale-105' onClick={() => navigate('/tracking/detail/' + encodeURIComponent(items._id) , {state:{id: items._id}})}><DotDotDotSvg color={'#63BDFF'} /></div> :
                                    items[h.field]
                                }</div>
                                )}
                            </div>
                        )}
                    </div>

                    <PageSelector
                        handleSelectPage={handleSelectPage}
                        page={page}
                        total={total}
                        totalPage={totalPage}
                    />
                </div>
            </div>
            {popType === 'date' && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)', zIndex:99}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Select a date range*</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setPopType (false)}}>X</div>         
                    <div className='flex flex-row my-4 w-full justify-around'>
                        <div className='w-[50px]'>From:</div>   
                        <input type='date' ref={fromRef} defaultValue={filterFrom} max={filterTo || yearMonthDay(new Date())} onChange={(e) => {handleParamChange('from', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='flex flex-row mb-4 w-full justify-around'>
                        <div className='w-[50px]'>To:</div>   
                        <input type='date' ref={toRef} defaultValue={filterTo} min={filterFrom || undefined} onChange={(e) => {handleParamChange('to', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='w-full flex flex-row items-center justify-between gap-2'>
                        <div className='button_blue w-[50%]' onClick={() => {setPopType(false)}}>Confirm</div>
                        <div className='button_blue w-[50%] text-white' onClick={() => {handleParamChange('clearDate')}}>Clear</div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Tracking)
